import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import { addSize } from "./Size.action";
import { setHeading } from "../../../accounts/account.actions";
const AddSize = (props) => {
  const [size, setSize] = useState("");
  const [sizeDesc, setSizeDesc] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function uploadSize() {
    props.addSize(
      {
        sizeName: size,
        sizeDesc: sizeDesc,
      },
      handleVisible,
      handleDangerVisible
    );
    setSize("");
    setSizeDesc("");
  }
  useEffect(() => {
    props.setHeading("Add Size");
    // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isAddingSize || props.isFetchingSize ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Size Name *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Size Name...."
                        onChange={(e) => setSize(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Size Description :</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={sizeDesc}
                        placeholder="Size Description...."
                        onChange={(e) => setSizeDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="sendDiv">
                  {isEmpty(size) ? (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "#1976d2",
                        border: "1px solid #1976d2",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button onClick={uploadSize}>Save</Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSize: (data, handleVisible, handleDangerVisible) =>
      dispatch(addSize(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  sizeList: state.sizeReducer.sizeList,
  isAddingSize: state.sizeReducer.isAddingSize,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSize);
