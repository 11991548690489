import React from 'react';
import { Page, Text, View, Document, StyleSheet, Svg, Line, Image } from '@react-pdf/renderer';
import logo from "../../../../Images/logo.png";
import { groupBy, isEmpty, get } from "lodash";

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 5,
    padding: 5,
    flexGrow: 1
  },
  textStyles: {
    marginLeft: 20
  },
  textStyles1: {
    marginLeft: 30
  },
  textStyles2: {
    marginLeft: 40
  },
  textStyles3: {
    marginLeft: 50
  },
  image: {
    width: "30%"
  },
});

var groupedLevel3;
var keys;
const PDFLevel5 = (props) => (
  console.log(props,"check"),
  groupedLevel3 = groupBy(!isEmpty(props.array) && props.array, function (x) {
    return x.levelFourData.levelOneId.name
  }),
  keys = !isEmpty(groupedLevel3) && Object.keys(groupedLevel3),

  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Image
          style={styles.image}
          src={props.data.restaurantImage}
        />
        <Text style={{ textAlign: "center", padding: "5%" }}>CompanyName: Green Apple</Text>
        {!isEmpty(keys) ? keys.map((y, index) => {
          let x = get(groupedLevel3, `${y}`);
          return (
            <View key={index}>
              <Text>{x[0].levelFourData.levelOneId.key}- {x[0].levelFourData.levelOneId.name}          {x[0].LevelOne.total_amount}</Text>
              {Object.keys(groupBy(x, y => y.levelFourData.levelTwoId.levelTwoName)) ? Object.keys(groupBy(x, y => y.levelFourData.levelTwoId.levelTwoName)).map((xyz, index1) => {
                let x1 = get(groupBy(x, y => y.levelFourData.levelTwoId.levelTwoName), `${xyz}`);
                return (
                  <View key={index1}>
                    <Text style={styles.textStyles}>{x1[0].levelFourData.levelOneId.key + "-" + x1[0].levelFourData.levelTwoId.levelTwoKey}- {x1[0].levelFourData.levelTwoId.levelTwoName}              {x1[0].levelTwo.total_amount}</Text>
                    {Object.keys(groupBy(x1, z => z.levelFourData.levelThreeId.levelThreeName)) ? Object.keys(groupBy(x1, z => z.levelFourData.levelThreeId.levelThreeName)).map((wxyz, index2) => {
                      let x2 = get(groupBy(x1, z => z.levelFourData.levelThreeId.levelThreeName), `${wxyz}`);
                      return (
                        <View key = {index2}>
                          <Text style = {styles.textStyles1}>{x2[0].levelFourData.levelOneId.key + "-" +x2[0].levelFourData.levelTwoId.levelTwoKey + "-" + x2[0].levelFourData.levelThreeId.levelThreeKey}- {x2[0].levelFourData.levelThreeId.levelThreeName}               {x2[0].levelThree.total_amount}</Text>
                          {Object.keys(groupBy(x2, w => w.levelFourData.levelFourName)) ? Object.keys(groupBy(x2, w => w.levelFourData.levelFourName)).map((vwxyz, index3) => {
                              let x3 = get(groupBy(x2, w => w.levelFourData.levelFourName), `${vwxyz}`);
                              return (
                                  <View key = {index3}>
                                <Text style = {styles.textStyles2}>{x3[0].levelFourData.levelOneId.key + "-" +x3[0].levelFourData.levelTwoId.levelTwoKey + "-" + x3[0].levelFourData.levelThreeId.levelThreeKey + "-" + x3[0].levelFourData.levelFourKey}- {x3[0].levelFourData.levelFourName}       {x3[0].levelFourData.levelBalance}    </Text>     
                                  
                              {/* {!isEmpty(x3) ? x3.map((lvl5, index4) => {
                                  return (
                                      <View key = {index4}>
                                          <Text style={styles.textStyles3}>{lvl5.levelFourData.allLevelKey}- {lvl5.levelFourData.levelFiveName}               {lvl5.levelFourData.levelBalance}</Text>
                                      </View>
                                  );
                              }) : ""} */}
                              </View>
                              );
                          }) : ""}
                        </View>
                      );
                    }) : ""}
                  </View>
                );
              }) : ""}
            </View>
          );
        }) : ""}
      </View>
    </Page>
  </Document>
);
export default PDFLevel5;