import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";

import { ModalBody, Modal } from "reactstrap";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined, ceil, parseInt } from "lodash";
import Spinner from "react-bootstrap/Spinner";

import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { getIssuance, searchIssuanceReport } from "./Issuance.action";
import { AsyncPaginate } from 'react-select-async-paginate';
import { baseURL, headers } from "../../../../../global/api";
const IssuanceReport = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      productQuantity: 0,
    },
  ]);

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [value, setValue] = useState();
  const [requistion, setRequistion] = useState();
  const [department, setdepartment] = useState();
  const [productionOrder, setProductionOrder] = useState();
  const [supervisor, setSupervisor] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [purchaseNameValue, setPurchaseNameValue] = useState();
  const [purchaseNameLabel, setPurchaseNameLabel] = useState();
  const [issuanceNameValue, setIssuanceNameValue] = useState();
  const [issuanceNameLabel, setIssuanceNameLabel] = useState();
  console.log(issuanceNameLabel, "ooooooooopqqqqqddddddddddddddd");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Issuance Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeGetIssuance = (selectedOption) => {
    setIssuanceNameValue(selectedOption.value);
    console.log(issuanceNameValue, "oooooooooooppppppppppppppp");
    setIssuanceNameLabel(selectedOption.label);
  };

  const getIssuanceList = props.IssuanceList.map((x) => {
    let data = { value: x._id, label: x.issuanceDate+" "+x.issuedBy };
    return data;
  });

  const getIssuanceList1 = (data)=> data.map((x) => {
    let data = { value: x._id, label: x.issuanceDate+"/"+x.issuanceDesc+"/"+x.department.department };
    return data;
  });

  const exportPDF = () => {
    props.searchIssuanceReport(
      {
        // startedDate : !isEmpty(startDate) ? startDate : "",
        // endDate: !isEmpty(endDate) ? endDate : "",
        issuanceId: issuanceNameValue,
      },
      issuanceNameLabel,
    //   startDate,
    //   endDate
    );
  };

  useEffect(() => {
    // props.getIssuance();
  }, []);

  async function loadOptions(search, loadedOptions, { page }) {
    const response = await fetch(`${baseURL}/issuance/?page=${!isEmpty(search)?"":page}&limit=${!isEmpty(search)?"":5}&search=${search}`, {headers:headers} );
    const responseJSON = await response.json();

   let check= responseJSON.total_results/responseJSON.limit;
   let check1= parseInt(check)!=responseJSON.page?true:false
   console.log(check,responseJSON.page,check1,"ttttttttttttttttttt111111")
    return {
      options: isEmpty(responseJSON.data)?[]: getIssuanceList1(responseJSON.data),
      hasMore: isEmpty(responseJSON.data)?false:check1,
      additional: {
        page: page+1 ,
      },
    };
  }
  return (
    <>
      {props.isFetchingIssuance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Issuance *</b>
                  </Form.Label>
                  {/* <Select
                    placeholder="Select Issuance..."
                    onChange={(e) => handleChangeGetIssuance(e)}
                    options={getIssuanceList}
                  /> */}
                  <AsyncPaginate
  value={value}
  loadOptions={loadOptions}
  onChange={(e) => {handleChangeGetIssuance(e);
    console.log(e,"eeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
    setValue(e)
  
  }}
  additional={{
    page: 0,
  }}
  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv" style={{marginTop:'15px'}}>
              {isUndefined(issuanceNameValue) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "#1976d2",
                    border: "1px solid #1976d2",
                  }}
                >
                  Generate Issuance Report
                </Button>
              ) : props.isFetchingSearchIssuanceList ? (
                <Button >Downloading PDF</Button>
              ) : (
                <Button  onClick={() => exportPDF()}>
                  Generate Issuance Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    // getPurchase: () => dispatch(getPurchase()),
    // getApproveDemand: () => dispatch(getApproveDemand()),
    // addIssuance: (data, handleVisible, handleDangerVisible) =>
    //   dispatch(addIssuance(data, handleVisible, handleDangerVisible)),
    // getAllInventory: (data) => dispatch(getAllInventory(data)),
    getIssuance: () => dispatch(getIssuance()),
    searchIssuanceReport: (data, issuanceName) =>
      dispatch(searchIssuanceReport(data, issuanceName)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingIssuance:state.IssuanceReducer.isFetchingIssuance,
  IssuanceList:state.IssuanceReducer.IssuanceList
});

export default connect(mapStateToProps, mapDispatchToProps)(IssuanceReport);
