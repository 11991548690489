import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { addTable, getHallById } from "./Table.action";

import { setHeading } from "../../../accounts/account.actions";
import { getUserRes } from "../../AddUser/user.actions";
const AddTable = (props) => {
  const [table, setTable] = useState("");
  const [tableDesc, setTableDesc] = useState("");
  const [tableLocation, setTableLocation] = useState(0);
  // const [warehouseKey, setWarehouseKey] = useState();
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantname, setRestaurantName] = useState("");
  const [hallId, setHallId] = useState("");
  const [hallname, setHallName] = useState("");
  const [tableCapacity, setTableCapacity] = useState(0);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const [waiterId, setWaiterId] = useState("");
  const [waiterName, setWaiterName] = useState("");

  const handleVisible = (message) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: message,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (message) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: message,
      icon: "error",
      button: "Ok",
    });
  };
  const handleChangeRestaurant = (selectedOption) => {
    console.log(selectedOption, "sop");
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
    setHallName(null);
    setHallId(null);
    props.getHall(selectedOption.value);
  };
  const getRestaurantList =
    !isEmpty(props.restaurantDataList) &&
    props.restaurantDataList.map((x) => {
      let data = { value: x._id, label: x.restaurantName };
      return data;
    });
  const handleChangeHall = (selectedOption) => {
    console.log(selectedOption, "sop");
    setHallId(selectedOption.value);
    setHallName(selectedOption.label);
  };
  const getHallList =
    !isEmpty(props.hallListById) &&
    props.hallListById.map((x) => {
      let data = { value: x._id, label: x.hallName };
      return data;
    });

  const filterdUserList =
    !isEmpty(props.userList) &&
    props.userList.filter((e) => e.role == "waiter");

  const WaiterList =
    !isEmpty(filterdUserList) &&
    filterdUserList.map((x) => {
      let data = { value: x._id, label: x.userName };
      return data;
    });
  console.log(filterdUserList, WaiterList, "ttttttttttttttttttttttttt");
  const handleChangeWaiter = (selectedOption) => {
    console.log(selectedOption, "sop");
    setWaiterName(selectedOption.label);
    setWaiterId(selectedOption.value);
  };

  function uploadTable() {
    props.addTable(
      {
        tableName: table,
        tableSittingCapacity: tableCapacity,
        tableDesc: tableDesc,
        hall: hallId,
        restaurant: restaurantId,
        waiterId: waiterId,
      },
      handleVisible,
      handleDangerVisible
    );
    setTable("");
    setRestaurantId("");
    setWaiterId("");
    setHallId("");
    setHallName("");
    setWaiterName("");
  }
  useEffect(() => {
    props.getRestaurantData();
    props.getUser();
    props.setHeading("Add Table");
  }, []);
  return (
    <>
      {props.isAddingTable ||
      props.isFetchingRestaurant ||
      props.isFetchingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Restaurant *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.restaurantDataList)}
                        placeholder="Select Restaurant..."
                        onChange={handleChangeRestaurant}
                        options={getRestaurantList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Hall *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.hallListById)}
                        placeholder="Select Hall..."
                        onChange={handleChangeHall}
                        options={getHallList}
                        value={{ label: hallname }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6" lg="6" md="6" sm="6">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b> Select Waiter *</b>
                      </Form.Label>
                      <Select
                        value={{ label: waiterName }}
                        // isDisabled={
                        //   isEmpty(props.seatSelected._id) ? false : true
                        // }
                        placeholder="Select Waiter..."
                        onChange={(e) => handleChangeWaiter(e)}
                        options={WaiterList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Table Name *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Table Name...."
                        onChange={(e) => setTable(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Table Sitting Capacity </b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Table Sitting Capacity...."
                        onChange={(e) => setTableCapacity(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Table Description :</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={tableDesc}
                        placeholder="Table Description...."
                        onChange={(e) => setTableDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="sendDiv">
                  <Button
                    disabled={
                      isEmpty(table) ||
                      isEmpty(restaurantId) ||
                      isEmpty(hallId) ||
                      isEmpty(waiterId)
                    }
                    style={{
                      marginLeft: "2%",
                      color: "white",
                      width: "20%",
                      backgroundColor: "#1976d2",
                      border: "1px solid #1976d2",
                    }}
                    onClick={uploadTable}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTable: (data, handleVisible, handleDangerVisible) =>
      dispatch(addTable(data, handleVisible, handleDangerVisible)),
    getUser: () => dispatch(getUserRes()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    setHeading: (data) => dispatch(setHeading(data)),
    getHall: (id) => dispatch(getHallById(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
  tableList: state.TableReducer.tableList,
  isAddingTable: state.TableReducer.isAddingTable,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  hallDataList: state.hallReducer.hallDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
  hallListById: state.TableReducer.hallListById,
  isFetchingHallById: state.TableReducer.isFetchingHallById,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTable);
