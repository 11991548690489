import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty, get } from "lodash";
import Select from "react-select";

import {
  getHybridAndCompleteData,
  searchInventoryLedger,
  searchInventoryLedgerReport,
} from "./InventoryLedger.action";
import { setHeading } from "../../../accounts/account.actions";
import { Link, Navigate } from "react-router-dom";

const InventoryLedger = (props) => {
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [item, setItem] = useState("");

  const getHybridAndCompleteData = props.hybridAndCompleteData.map((x) => {
    let data = { value: x._id, label: x.name };
    return data;
  });
  const handleChangeLevel5Ledger = (selectedOption) => {
    setItem(selectedOption.value);
  };

  const exportPDF = () => {
    props.searchInventoryLedgerList(
      {
        startDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        productId: item,
      },
      startDate,
      endDate
    );
  };
  // function searchData() {
  //   props.searchInventoryLedger({
  //     startDate: !isEmpty(startDate) ? startDate : "",
  //     endDate: !isEmpty(endDate) ? endDate : "",
  //     levelFourId: item,
  //   });
  //   return <Navigate to="/user/Ledger-List" />
  // }
  useEffect(() => {
    props.getHybridAndCompleteData();
    props.setHeading("Inventory Ledger");
  }, []);

  function searchData() {
    props.searchInventoryLedgerList(
      {
        startDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        productId: item,
      },
      startDate,
      endDate
    );
    return <Navigate to="/inventory/Ledger-List" />;
  }

  return (
    <>
      {props.isFetchingHybridAndCompleteData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingHybridAndCompleteData
              ? "Loading..."
              : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Product *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Product..."
                    onChange={handleChangeLevel5Ledger}
                    options={getHybridAndCompleteData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col
                lg="12"
                sm="12"
                className="text-center"
                style={{ marginTop: "2%" }}
              >
                {item == "" ? (
                  <Button
                    disabled
                    
                    
                  >
                    Search
                  </Button>
                ) : (
                  <Link
                    to={{
                      pathname: "/inventory/Ledger-List",
                      state: { start: startDate, end: endDate },
                    }}
                  >
                    <Button
                    
                      onClick={(e) => searchData()}
                    >
                      Search
                    </Button>
                  </Link>
                )}
              </Col>
            </Row>

            {/* <div className="sendDiv" style={{ marginTop: "10px" }}>
              {item == "" ? (
                <Button
                  disabled
                 
                >
                  Generate Ledger Report
                </Button>
              ) : (
                <Button  onClick={() => exportPDF()}>
                  Generate Ledger Report
                </Button>
              )}
            </div> */}
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHybridAndCompleteData: () => dispatch(getHybridAndCompleteData()),
    searchInventoryLedgerList: (data, startDate, endDate) =>
      dispatch(searchInventoryLedger(data, startDate, endDate)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  searchInventoryLedgerListReport:
    state.InventoryLegderReducer.searchInventoryLedgerListReport,
  isFetchingHybridAndCompleteData:
    state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  isFetchingSearchInventoryLedgerReport:
    state.InventoryLegderReducer.isFetchingSearchInventoryLedgerReport,
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryLedger);
