const initialState = {
  isFetchingHybridAndCompleteData: false,
  hybridAndCompleteData: [],
  searchInventoryLedgerListReport: [],
  isFetchingSearchInventoryLedgerReport: false,
  searchInventoryLedgerList: [],
  isFetchingSearchInventoryLedger: false,
};

const InventoryLegderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_HYBRID_AND_COMPLETE_DATA":
      return { ...state, isFetchingHybridAndCompleteData: true };
    case "SUCCESS_HYBRID_AND_COMPLETE_DATA":
      return {
        ...state,
        hybridAndCompleteData: action.payload.data,
        isFetchingHybridAndCompleteData: false,
      };
    case "ERROR_HYBRID_AND_COMPLETE_DATA":
      return { ...state, isFetchingHybridAndCompleteData: false };
    //
    case "REQUEST_SEARCH_INVENTORY_REPORT":
      return { ...state, isFetchingSearchInventoryLedgerReport: true };
    case "SUCCESS_SEARCH_INVENTORY_REPORT":
      return {
        ...state,
        searchInventoryLedgerListReport: action.payload,
        isFetchingSearchInventoryLedgerReport: false,
      };
    case "ERROR_SEARCH_INVENTORY_REPORT":
      return { ...state, isFetchingSearchInventoryLedgerReport: false };

      return { ...state };
    /////////search
    case "REQUEST_SEARCH_INVENTORY":
      return { ...state, isFetchingSearchInventoryLedger: true };
    case "SUCCESS_SEARCH_INVENTORY":
      return {
        ...state,
        searchInventoryLedgerList: action.payload,
        isFetchingSearchInventoryLedger: false,
      };
    case "ERROR_SEARCH_INVENTORY_REPORT":
      return { ...state, isFetchingSearchInventoryLedger: false };

    default:
      return state;
  }
};
export default InventoryLegderReducer;
