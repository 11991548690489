import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
  Breadcrumb,
  Navbar,
} from "react-bootstrap";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import jsPDF from "jspdf";
//import logo from "../../../../../../Images/logo.png";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import { getDateWiseInvoiceReport } from "./Report.action";
import IconMenu from "../../../drawer/IconMenu";

const DayWiseInvoiceReport = (props) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [view,setView]=useState(false);
 // const Data = isEmpty(props.InvoiceReportData) ? [] : [props.InvoiceReportData];
  const exportPDF = () => {
    props.getDateWiseInvoiceReport({ current_date: startDate });
  };
  const exportVIEW = () => {
    setView(true)
    props.getDateWiseInvoiceReport({ current_date: startDate });
  };
  console.log(props.invoiceReportData, "ddddddddd");
  //console.log(Data, "ddddddddd123");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [NoDataAlert, setNoDataAlert] = useState(false);
  const handleNoDataVisible = () => {
    setNoDataAlert(true);
    setTimeout(() => {
      setNoDataAlert(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "No Data Found!",
      icon: "Warning",
      button: "Ok",
    });
  };
  useEffect(() => {
     props.getDateWiseInvoiceReport({ current_date: startDate })
  }, []);
  return (
    <>
     <div >
    <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Day Wise Invoice Report</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
      </div>
      {props.isFetchingInvoiceReport ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
        
          <div className="main">

            <Row>
              <Breadcrumb>
                <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Day Wise Invoice Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <FormGroup>
                  <Form.Label>
                    <b>Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "2%" }}>
                <Button  style={{ marginRight: "1%" }} onClick={() => exportVIEW()}>
                  View Invoices Report
                </Button>
                <Button  onClick={() => exportPDF()}>
                  Generate Invoices Report
                </Button>
            </div> 
          {/* <MaterialTable
                        //style={{zIndex: 0}}
                            title="Invoice Report"
                            columns={[
                              { title: "Day Date", field: "dayOpenDate" }, //new
                              { title: "Day Name", field: "dayOpenName" }, //new
                              { title: "Restaurant", field: "restaurant.restaurantName" }, //new
                              { title: "Invoice Amount", field: "todaysInvoiceAmount" },
                              { title: "Invoice Length", field: "todaysInvoiceOrderLength" }
                            ]}
                            data={[]}
                            // options={{
                            //     actionsColumnIndex: -1,
                            //     filtering: true,
                            //     exportButton: true,
                            //     exportAllData: true,
                            // }}
                            options={{
                              actionsColumnIndex: -1,
                              // filtering: true,
                              exportButton: true,
                              exportAllData: true,
                              paging: true,
                              pageSize: 10, // make initial page size
                              emptyRowsWhenPaging: false, // To avoid of having empty rows
                              pageSizeOptions: [10, 20, 50], // rows selection options
                              headerStyle: {
                                position: "sticky",
                                top: 0,
                                color: "#00BBBB",
                                fontWeight: "550",
                                onRowAdd: "none",
                              },
                            }}
                        /> */}
                       
        </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDateWiseInvoiceReport: (data) => dispatch(getDateWiseInvoiceReport(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingInvoiceReport: state.ReportReducer.isFetchingInvoiceReport,
  invoiceReportData: state.ReportReducer.invoiceReportData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DayWiseInvoiceReport);
