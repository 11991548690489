import { filter, isEmpty, toLower } from "lodash";
const initialState = {
    sizeList: [],
    sizeDataList: [],
    isFetchingSize: false,
    isAddingSize: false,
};

const sizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_SIZE_GET_DATA":
        return { ...state, isFetchingSize: true, };
      case "SUCCESS_SIZE_GET_DATA":
        return {
            ...state,
           sizeDataList: action.payload.data,
            isFetchingSize: false,
        };
      case "ERROR_SIZE_GET_DATA":
        return { ...state,};
        //addsize
        case "REQUEST_ADD_SIZE":
          return { ...state, isAddingSize: true};
        case "SUCCESS_ADD_SIZE":
          return {
            ...state,
           sizeList: action.payload,
            isAddingSize: false,
          };
        case "ERROR_ADD_SIZE":
          return { ...state, isAddingSize: false};
      
      default:
        return state;
    }
  };
  export default sizeReducer;