import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";

import Select from "react-select";
import MaterialTable from "material-table";

import { Link } from "react-router-dom";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

const InventoryLegderDetail = (props) => {
  useEffect(() => {}, []);

  const [state, setState] = React.useState({
    columns: [
      {
        title: "Date",
        field: "vocDate",
        render: (rowData) => moment(rowData.vocDate).format("L"),
      },
      { title: "Voucher #", field: "voucherManualId" },
      { title: "Status", field: "status" },
    ],
    columnsData: [
      { title: "Account Title", field: "accountTittle" },
      { title: "Bill No", field: "billNumber" },
      { title: "Voucher Description", field: "voucherDescription" },
      { title: "Debit", field: "debit" },
      { title: "Credit", field: "credit" },
      //   { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") }
    ],
  });
console.log(props.searchInventoryLedgerList,"test")
  return (
    <>
      {props.isFetchingSearchInventoryLedger ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            Loading...
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div className="sendDiv">
              <Link to="/inventory/ledger">
                <Button> Back to Search</Button>
              </Link>
            </div>

            <div className="table">
              <h4>Product Name:{props.searchInventoryLedgerList.code }-{props.searchInventoryLedgerList.productName}</h4>
              <h4>Total Add Quantity: {props.searchInventoryLedgerList.addQuantity}</h4>
              <h4>Total Less Quantity: {props.searchInventoryLedgerList.lessQuantity}</h4>
              <h4>Closing Balance: {props.searchInventoryLedgerList.closing_balance}</h4>
              <MaterialTable
                title={props.searchInventoryLedgerList.productName}
                
                columns={[
                  { title: "Date", field: "transactionDate" },

                  { title: "Opening Balance", field: "openingBalance" },
                  { title: "Description", field: "description" },
                  { title: "Add Quantity", field: "addQuantity" },
                  { title: "Less Quantity", field: "lessQuantity" },

                  { title: "Closing Balance", field: "closingBalance" },

                  //   { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") }
                ]}
                data={props.searchInventoryLedgerList.data}
                options={{
                  actionsColumnIndex: -1,
                  filtering: true,
                  exportButton: true,
                  paging: true,
                  pageSize: 50, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [50, 100, 150, 200],
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
const mapStateToProps = (state, ownProps) => ({
  searchInventoryLedgerList:
    state.InventoryLegderReducer.searchInventoryLedgerList,
  isFetchingSearchInventoryLedger:
    state.InventoryLegderReducer.isFetchingSearchInventoryLedger,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryLegderDetail);
