const initialState = {
  isAddingDeal: false,
  isFetchingDeal: false,
  dealDataList: [],
  dealList: [],
};

const CreateDealReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_DEAL":
      return { ...state, isAddingDeal: true };
    case "SUCCESS_ADD_DEAL":
      return {
        ...state,
        dealList: action.payload,
        isAddingDeal: false,
      };
    case "ERROR_ADD_DEAL":
      return { ...state, isAddingDeal: false };
    case "REQUEST_DEALS_GET_DATA":
      return { ...state, isFetchingDeal: true };
    case "SUCCESS_DEALS_GET_DATA":
      return {
        ...state,
        dealDataList: action.payload.data,
        isFetchingDeal: false,
      };
    case "ERROR_DEALS_GET_DATA":
      return { ...state, isFetchingDeal: false };
    default:
      return state;
  }
};
export default CreateDealReducer;
