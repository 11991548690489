import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
  Breadcrumb,
  Navbar,
} from "react-bootstrap";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import jsPDF from "jspdf";
//import logo from "../../../../../../Images/logo.png";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import { getCoupons } from "../AddCoupons/Coupons.action";
import { getDailyPromoReport } from "./Report.action";
import IconMenu from "../../../drawer/IconMenu";

const DayWisePromoReport = (props) => {
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [couponsName, setCouponsName] = useState("");
  const [couponsId, setCouponsId] = useState("");
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };
  const getItem = props.couponsDataList.map((x) => { let data = { value: x._id, label: x.name}; return data });
  
  console.log(getItem,"jjjjjj")
  const handleChangeLedgerItem = (selectedOption) => {
    setCouponsId(selectedOption.value);
    setCouponsName(selectedOption.label);
  };
  const exportPDF = () => {
    props.getDailyPromoReport({ 
      current_date: startDate,
      coupon:couponsId
     },
     handleDangerVisible);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    props.getCoupon();
    // props.getDateWisePromoReport({ current_date: startDate })
  }, []);
  return (
    <>
        <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Day Wise Promo Report</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar>
      {props.isFetchingPromoReport||props.isFetchingCouponsData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
        
          <div className="main">

            <Row>
              <Breadcrumb>
                <Breadcrumb.Item ><Link to="/home"> Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item ><Link to="/report">Report</Link> </Breadcrumb.Item>
                <Breadcrumb.Item active>Day Wise Promo Report</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>Date :</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col lg="6" md="6" sm="6" >
                    <Form.Group>
                    <Form.Label>
                    <b>Selet Coupon :</b>
                  </Form.Label>
                      <Select
                        placeholder="Select Coupon..."
                        onChange={handleChangeLedgerItem}
                          options={getItem}
                      />
                    </Form.Group>
                  </Col>
            </Row>

            <div className="sendDiv" style={{ marginTop: "2%" }}>
              {isUndefined(startDate) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    
                    border: "1px solid ",
                  }}
                >
                  Generate Promos Report
                </Button>
              ) : props.isFetchingDateWisePromoReport ? (
                <Button >Downloading PDF</Button>
              ) : (
                <Button  onClick={() => exportPDF()}>
                  Generate Promo Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDailyPromoReport: (data,handleDangerVisible) => dispatch(getDailyPromoReport(data,handleDangerVisible)),
    getCoupon:()=>dispatch(getCoupons()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  couponsDataList: state.CouponReducer.couponsDataList,
  isFetchingCouponsData: state.CouponReducer.isFetchingCouponsData,
  isFetchingPromoReport: state.ReportReducer.isFetchingPromoReport,
  promoReportData: state.ReportReducer.promoReportData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DayWisePromoReport);
