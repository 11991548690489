import API from "../../../../../global/api";
export const requestFlavorsData = () => {
  return {
    type: "REQUEST_FLAVORS_GET_DATA",
  };
};
export const successFlavorsGetData = (data) => {
  return {
    type: "SUCCESS_FLAVORS_GET_DATA",
    payload: data,
  };
};
export const errorFlavorsGetData = () => {
  return {
    type: "ERROR_FLAVORS_GET_DATA",
  };
};
export const getFlavorsData = () => {
  return (dispatch) => {
    dispatch(requestFlavorsData());
    API.get(`/flavour`)
      .then((res) => {
        let getData = res.data;
        dispatch(successFlavorsGetData(getData));
      })
      .catch((error) => {
        dispatch(errorFlavorsGetData());
      });
  };
};
export const requestAddFlavors = () => {
  return {
    type: "REQUEST_ADD_FLAVORS",
  };
};
export const successAddFlavors = (data) => {
  return {
    type: "SUCCESS_ADD_FLAVORS",
    payload: data,
  };
};
export const errorAddFlavors = () => {
  return {
    type: "ERROR_ADD_FLAVORS",
  };
};

export const addFlavors = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddFlavors());
    API.post(`/flavour`, data)
      .then((res) => {
        dispatch(successAddFlavors(data));
        handleVisible(res.data.message);
      })
      .catch((error) => {
        dispatch(errorAddFlavors());
        handleDangerVisible(error.response.data.message);
      });
  };
};
export const DeleteRequestFlavors = () => {
  return {
    type: "DELETE_REQUEST_FLAVORS",
  };
};
export const DeleteSuccessFlavors = (user_id) => {
  return {
    type: "DELETE_SUCCESS_FLAVORS",
    user_id: user_id,
  };
};
export const DeleteErrorFlavors = () => {
  return {
    type: "DELETE_ERROR_FLAVORS",
  };
};
export const deleteFlavors = (vendorId) => {
  return (dispatch) => {
    dispatch(DeleteRequestFlavors());
    API.delete(`/flavour/${vendorId}`)
      .then((res) => {
        dispatch(getFlavorsData());
        dispatch(DeleteSuccessFlavors(vendorId));
      })
      .catch((error) => {
        dispatch(DeleteErrorFlavors());
      });
  };
};
export const UpdateRequestFlavors = () => {
  return {
    type: "UPDATE_REQUEST_FLAVORS",
  };
};
export const UpdateSuccessFlavors = (data) => {
  return {
    type: "UPDATE_SUCCESS_FLAVORS",
    payload: data,
  };
};

export const UpdateErrorFlavors = () => {
  return {
    type: "UPDATE_ERROR_FLAVORS",
  };
};

export const updateFlavors = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestFlavors());
    API.put(
      `/flavour/${oldData._id}`,
      {
        flavourName: data.flavourName,
        flavourDesc: data.flavourDesc,
      },
      {}
    )
      .then((res) => {
        dispatch(getFlavorsData());
        dispatch(UpdateSuccessFlavors([data]));
      })
      .catch((error) => dispatch(UpdateErrorFlavors()));
  };
};
