const initialState = {
  generateBarcode: [],
  isGeneratingBarcode: false,

  };
  
  const BarcodeReducer = (state = initialState, action) => {
    switch (action.type) {
            
      //addhall
      case "REQUEST_GENERATE_BARCODE":
        return { ...state, isGeneratingBarcode: true};
      case "SUCCESS_GENERATE_BARCODE":
        return {
          ...state,
          generateBarcode: action.payload,
          isGeneratingBarcode: false,
        };
      case "ERROR_GENERATE_BARCODE":
        return { ...state, isGeneratingBarcode: false};
  
      default:
        return state;
    }
  };
  export default BarcodeReducer;
  