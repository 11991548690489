const initialState = {

    adminDemandList: [],
    isFetchingadminDemandList: false,
    voucherList: [],
    isFetchingadminVoucherList: false,
    purchaseAdminList: [],
    isFetchingadminPurchaseList: false,
    returnsAdminList: [],
    isFetchingadminReturnList: false,
    selectedrow: ""
}


const adminDemandDetailReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADMIN_DEMAND":
            return { ...state, isFetchingadminDemandList: true };
        case "SUCCESS_ADMIN_DEMAND":

            return {
                ...state,
                adminDemandList: action.payload.data,
                isFetchingadminDemandList: false,
            };
        case "ERROR_ADMIN_DEMAND":
            return { ...state, isFetchingadminDemandList: false };

        case "REQUEST_STATUS_UPDATE":
            return { ...state };
        case "SUCCESS_STATUS_UPDATE":
            return { ...state, adminDemandList: state.adminDemandList.map(x => action.payload.data.find(({ demandId }) => demandId === x.demandId) || x) };
        case "ERROR_STATUS_UPDATE":
            return { ...state };

        case "REQUEST_ADMIN_VOUCHER":
            return { ...state, isFetchingadminVoucherList: true };
        case "SUCCESS_ADMIN_VOUCHER":

            return {
                ...state,
                voucherList: action.payload.data,
                isFetchingadminVoucherList: false,
            };
        case "ERROR_ADMIN_VOUCHER":
            return { ...state, isFetchingadminVoucherList: false };


        case "REQUEST_STATUS_UPDATE_VOUCHER":
            return { ...state };
        case "SUCCESS_STATUS_UPDATE_VOUCHER":
            return { ...state, voucherList: state.voucherList.map(x => action.payload.data.find(({ voucherId }) => voucherId === x.voucherId) || x) };
        case "ERROR_STATUS_UPDATE_VOUCHER":
            return { ...state };

        case "REQUEST_ADMIN_PURCHASE":
            return { ...state, isFetchingadminPurchaseList: true };
        case "SUCCESS_ADMIN_PURCHASE":

            return {
                ...state,
                purchaseAdminList: action.payload.data,
                isFetchingadminPurchaseList: false,
            };
        case "ERROR_ADMIN_PURCHASE":
            return { ...state, isFetchingadminReturnList: false };

            case "REQUEST_ADMIN_RETURN":
              return { ...state, isFetchingadminReturnList: true };
          case "SUCCESS_ADMIN_RETURN":
  
              return {
                  ...state,
                  returnsAdminList: action.payload.data,
                  isFetchingadminReturnList: false,
              };
          case "ERROR_ADMIN_RETURN":
              return { ...state, isFetchingadminPurchaseList: false };
  
        case "REQUEST_STATUS_UPDATE_PURCHASE":
            return { ...state };
        case "SUCCESS_STATUS_UPDATE_PURCHASE":
            return { ...state, purchaseAdminList: state.purchaseAdminList.map(x => action.payload.data.find(({ purchaseOrderId }) => purchaseOrderId === x.purchaseOrderId) || x) };
        case "ERROR_STATUS_UPDATE_PURCHASE":
            return { ...state };



            case "UPDATE_REQUEST_DEMAND":
      return { ...state };
    case "UPDATE_SUCCESS_DEMAND":
      return { ...state, adminDemandList: state.adminDemandList.map(x => action.payload.find(({ demandId }) => demandId === x.demandId) || x) };
    case "UPDATE_ERROR_DEMAND":
      return { ...state };

      case "DELETE_REQUEST_DEMAND":
      return { ...state };
    case "DELETE_SUCCESS_DEMAND":
      return { ...state, adminDemandList: state.adminDemandList.filter(item => item.demandId !== action.demandId) };
    case "DELETE_ERROR_DEMAND":
      return { ...state };


      case "DELETE_REQUEST_DEMAND_MATERIAL":
        return { ...state };
      case "DELETE_SUCCESS_DEMAND_MATERIAL":
        return { ...state, adminDemandList: state.adminDemandList.filter(item => item.demand_quantities.map((x) => {return x.demandQuantityId}) !== action.demandQuantityId) };
      case "DELETE_ERROR_DEMAND_MATERIAL":
        return { ...state };



      case "DELETE_REQUEST_PURCHASE":
        return { ...state };
      case "DELETE_SUCCESS_PURCHASE":
        return { ...state, purchaseAdminList: state.purchaseAdminList.filter(item => item.purchaseOrderId !== action.purchaseOrderId ) };
      case "DELETE_ERROR_PURCHASE":
        return { ...state };


        case "DELETE_REQUEST_PURCHASE_MATERIAL":
            return { ...state };
          case "DELETE_SUCCESS_PURCHASE_MATERIAL":
            return { ...state, purchaseAdminList: state.purchaseAdminList.filter(item => item.create_demand.purchase_data.map((x) => {return x.purchaseDataId }) !== action.purchaseDataId ) };
          case "DELETE_ERROR_PURCHASE_MATERIAL":
            return { ...state };


            case "UPDATE_REQUEST_PURCHASE":
                return { ...state };
              case "UPDATE_SUCCESS_PURCHASE":
                return { ...state, purchaseAdminList: state.purchaseAdminList.map(x => action.payload.find(({ purchaseOrderId }) => purchaseOrderId === x.purchaseOrderId) || x) };
              case "UPDATE_ERROR_PURCHASE":
                return { ...state };

                case 'SAVE_ID':
                  return {...state,selectedrow: action.payload}

        default:
            return state;
    }
};
export default adminDemandDetailReducer;