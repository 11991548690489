import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  isGettingPendingOrder: false,
  PendingOrderList: [],
  SearchPendingOrderList:[],
};

const KDSReducer = (state = initialState, action) => {
  switch (action.type) {
    // Get Pending order
    case "REQUEST_GET_PENDING_ORDER":
      return { ...state, isGettingPendingOrder: true };
    case "SUCCESS_GET_PENDING_ORDER":
      return {
        ...state,
        PendingOrderList: action.payload.data,
        SearchPendingOrderList: action.payload.data,
        isGettingPendingOrder: false,
      };
    case "ERROR_GET_PENDING_ORDER":
      return { ...state, isGettingPendingOrder: false };
      case "FILTER_ORDER":
        console.log(state.PendingOrderList,"ttttttttttttttt111")
         return {
           ...state,
           SearchPendingOrderList:action.payload==""?state.PendingOrderList:filter(state.PendingOrderList, x => x.number == action.payload) 
         };
         case "FILTER_ORDER_BY_TABLE":
           console.log(state.PendingOrderList,"ttttttttttttttt111")
            return {
              ...state,
              SearchPendingOrderList:action.payload==""?state.PendingOrderList:filter(state.PendingOrderList, x => x.tableName == action.payload) 
            };
    default:
      return state;
  }
};
export default KDSReducer;
