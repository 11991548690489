import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, FormGroup } from "react-bootstrap";
import "../add-details.css";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";

import { addReceiving, getPDFById, getReceiving } from "./Receiving.action";

const RecevingReportById = (props) => {
  const [ReceivingId, setReceivingId] = useState("");
  
  const handleChangeReceiving = (selectedOption) => {
    console.log(selectedOption, "sop");
    setReceivingId(selectedOption.value);
  };
  
  function uploadPurchase() {
    props.getReceivingPdf(ReceivingId);
     setReceivingId("")
}
  useEffect(() => {
   props.getReceiving()
  }, []);
  const getRecevingList =
  !isEmpty(props.ReceivingList) &&
  props.ReceivingList.map((x) => {
    let data = {
      value: x._id,
      label: x.receivedBy,
    };
    return data;
  });
  return (
    <>
      {props.isFetchingReceiving ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingReceiving ? 'Loading...' : 'Loading...'}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Received By:</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.ReceivingList)}
                    placeholder="Select Received By..."
                    onChange={handleChangeReceiving}
                    options={getRecevingList}
                  />
                </Form.Group>
              </Col>
            </Row>
            
            <div className="sendDiv">
              {
                isEmpty(ReceivingId) ? (
                  <Button
                    disabled
                    // style={{ color: "black" }}
                
                  >
                    Generate PDF
                  </Button>
                ) : (
                  <Button  onClick={uploadPurchase}>
                  
                    Generate PDF
                  </Button>
                )
              }
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReceiving:()=>dispatch(getReceiving()),
    getReceivingPdf:(id)=>dispatch(getPDFById(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
    ReceivingList:state.RecivingReducer.ReceivingList,
    isFetchingReceiving:state.RecivingReducer.isFetchingReceiving,
});

export default connect(mapStateToProps, mapDispatchToProps)(RecevingReportById);
