import React from "react";

import "./seats.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Button, Container, Spinner, Image } from "react-bootstrap";
import { getTableData, getTableReserved } from "../AddTable/Table.action";
import { isEmpty, filter } from "lodash";
import { redirect } from "react-router-dom";
// import tablelogo from "../../../../../Images/table.jpg"
const DrawGrid = (props) => {
  console.log(props.tableListReserved, "abc");
  // const reservedId=!isEmpty(props.tableListReserved) &&props.tableListReserved.map((x) => {
  //   let data = { value: x.tableId };
  //   return data;
  // });
  // {console.log(reservedId,props.seat,"abcdd11")}
  //  const reservedarray= filter(props.seat, { '_id': reservedId.map((x)=>x.value) })
  //   {console.log(reservedarray,"abcdd")}
  const onClickSeat = (seat) => {
    console.log(seat, "aaaa");
    props.onClickData(seat);
  };
  return (
    <Container fluid>
      {props.isFetchingTable ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingTable ? "Loading..." : "Saving..."}
          </Button>
        </div>
      ) : (
        <div className="container">
          <table className="grid">
            <tbody>
              <tr>
                {!isEmpty(props.seat) ? (
                  props.seat.map((row) => 
                  //  console.log(row,"rowcheck")
                  ( 
                    <td
                      key={row._id}
                      //disabled={true}
                      onClick={(e) =>
                        props.checktrue(row) ? onClickSeat(row) : null
                      }
                      // style={{dis}}
                    >
                      {/* <Image src={tablelogo} /> */}
                      {row.tableName}
                      <p>{row.tableSittingCapacity}</p>

                      {!isEmpty(props.tableListReserved)
                        ? props.tableListReserved.map((x) =>
                            x.tableId == row._id ? <p style={{marginTop:'-15%'}}>Reserved <span>{x.status}</span></p>
                            
                            : ""
                           
                          )
                        : ""}
                        </td>
                    
                  ))
                ) : (
                  <Row>
                    {/* <Col xl="4"
          lg="4"
          md="4"
          sm="4"></Col>  */}
                    <Col
                      xl="12"
                      lg="12"
                      md="12"
                      sm="12"
                      className="d-flex justify-content-center mt-2 mb-2"
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      No Table added Yet
                    </Col>
                    {/* <Col 
        xl="4"
        lg="4"
        md="4"
        sm="4"
        ></Col> */}
                  </Row>
                )}
                {/* <p style={{textAlign:'center',fontWeight:'bold',color:'red'}}></p> */}
              </tr>
            </tbody>
          </table>
          <div hidden={isEmpty(props.seat) ? true : false}>
            {/* <Row>
              <Col
                xl="12"
                lg="12"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 mb-2"
              >
                <Link to={"/pos"}>
                  <Button
                    onClick={() => props.handleSubmited()}
                    style={{ marginRight: 20 }}
                  >
                    Confirm Booking
                  </Button>
                </Link>
              </Col>
            </Row> */}
          </div>
        </div>
      )}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTable: () => dispatch(getTableData()),
    getReservedTable: () => dispatch(getTableReserved()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  tableDataList: state.TableReducer.tableDataList,
  tableListReserved: state.TableReducer.tableListReserved,
  isFetchingTable: state.TableReducer.isFetchingTable,
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawGrid);
