import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import MaterialFlavors from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import { useEffect } from "react";
import { deleteDeals, getDealsData } from "./createDeal.action";
const ViewDeals = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    props.setHeading("Deal List");
  }, []);
  return (
    <>
      {props.isFetchingFlavors ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialFlavors
              title="Deals Details"
              columns={[
                { title: "Deal Name", field: "name" },
                { title: "Deals Description", field: "desc" },
                { title: "Deal Sale Price", field: "salePrice" },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/deal?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              detailPanel={(rowData) => {
                return (
                  <MaterialFlavors
                    columns={[
                      { title: "Deal Product ", field: "ProductLabel" },
                      { title: "Variation", field: "ProductVariationLabel" },
                      { title: "Quantity", field: "quantity" },
                    ]}
                    data={rowData.variation}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: true,
                      sorting: true,
                      paging: true,
                      pageSize: 200, // make initial page size
                      emptyRowsWhenPaging: false, // To avoid of having empty rows
                      pageSizeOptions: [50, 100, 150, 200], // rows selection options
                      headerStyle: {
                        position: "sticky",
                        top: 0,
                        color: "#00BBBB",
                        fontWeight: "550",
                        onRowAdd: "none",
                      },
                    }}
                  />
                );
              }}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageFlavors: 50, // make initial page flavors
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageFlavorsOptions: [30, 50,100,150,200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                isDeleteHidden: (rowData) =>
                  Checkpermissions("permissionsSubCatName", "Deal Delete"),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteDeal(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDeals: () => dispatch(getDealsData()),
    deleteDeal: (Id) => dispatch(deleteDeals(Id)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ViewDeals);
