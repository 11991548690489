import API from "../../../../../global/api";
import { get, isEmpty } from 'lodash';
import jsPDF from "jspdf";
import moment from "moment";




//Get order types

export const requestOrderTypes = () => {
    return {
        type: "REQUEST_ORDER_TYPES",
    };
};
export const successOrderTypes = (data) => {
    console.log(data, " order data");
    return {
        type: "SUCCESS_ORDER_TYPES",
        payload: data,
    };
};
export const errorOrderTypes = () => {
    return {
        type: "ERROR_ORDER_TYPES",
    };
};


//Get tables

export const requestTables = () => {
    return {
        type: "REQUEST_TABLES",
    };
};
export const successTables = (data) => {
    return {
        type: "SUCCESS_TABLES",
        payload: data,
    };
};
export const errorTables = () => {
    return {
        type: "ERROR_TABLES",
    };
}
//Add tables

export const requestAddTables = () => {
    return {
        type: "REQUEST_ADD_TABLES",
    };
};
export const successAddTables = (data) => {
    return {
        type: "SUCCESS_ADD_TABLES",
        payload: data,
    };
};
export const errorAddTables = () => {
    return {
        type: "ERROR_ADD_TABLES",
    };
}
//DELETE tables

export const requestDeleteTables = () => {
    return {
        type: "REQUEST_DELETE_TABLES",
    };
};
export const successDeleteTables = (data) => {
    return {
        type: "SUCCESS_DELETE_TABLES",
        payload: data,
    };
};
export const errorDeleteTables = () => {
    return {
        type: "ERROR_DELETE_TABLES",
    };
}
//
export const requestCustomersData = () => {
    return {
        type: "REQUEST_CUSTOMERS_GET_DATA",
    };
};
export const successCustomersGetData = (data) => {
    return {
        type: "SUCCESS_CUSTOMERS_GET_DATA",
        payload: data,
    };
};
export const errorCustomersGetData = () => {
    return {
        type: "ERROR_CUSTOMERS_GET_DATA",
    };
};


//Add Customer Data
export const requestAddCustomer = () => {
    return {
        type: "REQUEST_ADD_CUSTOMER",
    };
};
export const successAddCustomer = (data) => {
    return {
        type: "SUCCESS_ADD_CUSTOMER",
        payload: data,
    };
};
export const errorAddCustomer = () => {
    return {
        type: "ERROR_ADD_CUSTOMER",
    };
};

//Reset state to default
export const resetStateCustomers = () => {
    return {
        type: "RESET_STATE_Customers",
    };
};
//sET ACTIOS FOR SEATS
export const setSeatAvaliable = (data) => {
    return {
        type: "SET_SEAT_AVAILABLE",
        payload: data,
    };
};
export const setSeatSelected = (data) => {
    console.log(data, "dddddddddddddddddddddddddddddddd222")
    return {
        type: "SET_SEAT_SELECTED",
        payload: data,
    };
};
export const setSeatReserved = (data) => {
    console.log(data, "dddddddddddddddddddddddddddddddd")
    return {
        type: "SET_SEAT_RESERVEDX",
        payload: data,
    };
};







//API CALLS FOR Customers


export const getCustomersData = (limit, page, search) => {
    return (dispatch) => {
        dispatch(requestCustomersData());
        API.get(`/customer/get_all_customer?size=${limit}&page=${page}&search=${search}`).then((res) => {
            let getData = res.data;

            dispatch(successCustomersGetData(getData));
        }).catch((error) => {
            dispatch(errorCustomersGetData());
        })
    };
};

//Without pagination

export const getCustomersDataWithoutPagination = (limit, page, search) => {
    return (dispatch) => {
        dispatch(requestCustomersData());
        API.get(`/customer/get_all_customer_menu`).then((res) => {
            let getData = res.data;
            dispatch(successCustomersGetData(getData.data));
        }).catch((error) => {
            dispatch(errorCustomersGetData());
        })
    };
};


//get tables


export const getTables = () => {
    return (dispatch) => {
        dispatch(requestTables());
        API.get(`/restaurant_table/get_restaurant_table`).then((res) => {
            let getData = res.data;
            dispatch(successTables(getData));
        }).catch((error) => {
            dispatch(errorTables());
        })
    };
};

//add tables


export const addTables = (table, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddTables());
        API.post(`/restaurant_table/add_restaurant_table`, table).then((res) => {
            let getData = res.data;
            dispatch(successAddTables(getData.data));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddTables());
            handleDangerVisible();
        })
    };
};


//DELETE tables


export const deleteTables = (table, handleVisible, handleDangerVisible, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestDeleteTables());
        API.get(`/restaurant_table/add_restaurant_table/${table}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteTables(getData.data));
        }).catch((error) => {
            dispatch(errorDeleteTables());
        })
    };
};

//get order types

//Without pagination

export const getOrderTypes = () => {
    return (dispatch) => {
        dispatch(requestOrderTypes());
        API.get(`/restaurant_table/get_order_types`).then((res) => {
            let getData = res.data;
            console.log(getData, " order getData");

            dispatch(successOrderTypes(getData));
        }).catch((error) => {
            dispatch(errorOrderTypes());
        })
    };
};

//Adding single vender

export const addCustomer = (data, handleVisible, handleDangerVisible, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestAddCustomer());
        API.post(`/customer/add_customer`, data).then((res) => {
            dispatch(getCustomersData());
            dispatch(successAddCustomer(data));
            handleVisible();
            updateTableQuery();
        }).catch((error) => {
            dispatch(errorAddCustomer());
            handleDangerVisible();
        })
    };
};

//Api call for deleting Customer


export const deleteCustomer = (customerId) => {
    return dispatch => {
        //  dispatch(requestDeleteInventory());
        API.delete(`/customer/delete_customer/${customerId}`)
            .then(res => {
                dispatch(getCustomersData());
                //  dispatch(successDeleteCustomer(wareHouseId))
            }).catch((error) => { }
                //    dispatch(errorDeleteCustomer())
            );
    }
};
//order
export const requestAddOrders = () => {
    return {
        type: "REQUEST_ADD_ORDERS",
    };
};
export const successAddOrders = (data) => {
    return {
        type: "SUCCESS_ADD_ORDERS",
        payload: data,
    };
};
export const errorAddOrders = () => {
    return {
        type: "ERROR_ADD_ORDERS",
    };
};
export const AddOrders = (data, handleVisible, handleDangerVisible) => {
    console.log(getPendingOrder, "testtttttt")
    return (dispatch) => {
        dispatch(requestAddOrders());
        API.post(`/orders`, data).then((res) => {
            dispatch(successAddOrders(data));
            console.log(res)
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddOrders());
            console.log(error, "kkkkkkkkkkk")
            handleDangerVisible(isEmpty(error.response.data) ? "Server Error" : error.response.data.message);
        })
    };
};
export const AddOrdersPrint = (data, handleVisible, handleDangerVisible, printType) => {
    console.log(getPendingOrder, "testtttttt")
    return (dispatch) => {
        dispatch(requestAddOrders());
        API.post(`/orders`, data).then((res) => {
            dispatch(successAddOrders(data));
            console.log(res)
            handleVisible(res.data.message);
            const pdfData = res.data.item;
            const pdfDataCus = res.data.item.customer;
            const resData = res.data;
            const unit = "mm";
            const size = [80, 3000]; // Use A1, A2, A3 or A4
            const orientation = "potrait"; // portrait or landscape

            const marginLeft = 0;
            const doc = new jsPDF(orientation, unit, size);

            const title = `Order ${pdfData.number}`;
            const companyName = `${resData.resImage.restaurantName}`;
            const companyAddress = `${resData.resImage.restaurantLocation}`;
            const companyPhone = `${resData.resImage.restaurantPhone}`;
            const logo1 = resData.restaurantImage;
            // doc.addImage(logo1, "PNG", 1, 1, 70, 20);
            doc.setFontSize(18);
            doc.setFont(undefined, "bold");
            doc.text(companyName, 15, 6);
            doc.setFontSize(12);
            doc.setFont("TimesNewRoman", "Normal");
            doc.text(companyPhone, 15, 11);
            var companyAddressSplit = doc.splitTextToSize(companyAddress, 60)
            doc.text(companyAddressSplit, 15, 16);
            var y=16
            for(let i=0;i<companyAddressSplit.length;i++){
                y=y+4
            }
           
            // // doc.setFontSize(18);
            // doc.setFontSize(12);
            // doc.setFont("TimesNewRoman", "Normal");
            // doc.text(title, 2, 26 + 2);
            doc.text(printType == "Customer" ? 'Customer Copy' : "Kitchen Copy", 1, y+1,);
            doc.text(`Date : ${pdfData.orderDate}`, 1, y+6)
            doc.text(`Order No : ${pdfData.number}`, 1, y+11)
            doc.text(`Order Type : ${pdfData.orderType}`, 1, y+16)
            doc.text(`Order Time : ${moment(pdfData.createdAt).format("llll")}`,1,y+21)
            printType == "Customer" && pdfData.orderType == "Delivery" && doc.text(`Name : ${pdfDataCus.name}`, 1, y+26)
            printType == "Customer" && pdfData.orderType == "Delivery" && doc.text(`Phone : ${pdfDataCus.phone}`, 1, y+31)

            const address = `${pdfDataCus.address} ${pdfDataCus.address2
                } ${pdfDataCus.address3}`
            var splitAddress = doc.splitTextToSize(address, 60)
            // var li =splitAddress.length
            var y1=y+36
            for(let i=0;i<splitAddress.length;i++){
                y1=y1+4
            }
            printType == "Customer" && pdfData.orderType == "Delivery" && doc.text(`Address : `, 1, y+36)
            printType == "Customer" && pdfData.orderType == "Delivery" && doc.text(splitAddress, 18, y+36)
            // doc.text(`Order Type ${pdfData.orderType}`, 25, 26 + 2)
            // doc.text(`Customer Name ${pdfData.customer.name}`, 2, 26 + 4);
            // doc.text(`Waiter Name ${pdfData.waiterId.userName}`, 25, 26 + 4);
            // doc.text(`Table # ${pdfData.tableName}`, 10, 135);
            doc.setFontSize(12);
            const headers = [
                [
                    // "Sr",
                    "Product",
                    "Qty",
                    "Price",
                    "Total"
                ],
            ];

            const data = pdfData.items.map((elt, index) => [
                `${elt.productLabel}-(${elt.productVariationLabel})`,
                elt.quantity,
                elt.productSellingPrice,
                elt.productTotalAmount,
            ]);
            const headersKit = [
                [
                    // "Sr",
                    "Product",
                    "Qty",
                ],
            ];

            const dataKit = pdfData.items.map((elt, index) => [
                `${elt.productLabel}-(${elt.productVariationLabel})`,
                elt.quantity,
            ]);

            let content = {
                startY: printType == "Customer" && pdfData.orderType == "Delivery" ? y1+2 : y+26,
                // startX: 2,
                head: printType == "Customer" ? headers : headersKit,
                body: printType == "Customer" ? data : dataKit,
                theme: 'plain',
                //  textColor: [0, 0, 0],
                headerStyles: {
                    lineWidth: 0.5,
                    lineColor: [255, 0, 0],
                    // textColor: [0, 0, 0],
                },
                bodyStyles: {
                    lineWidth: 0.5,
                    lineColor: [255, 0, 0],
                    // textColor: [0, 0, 0],
                },
                columnStyles: {
                    0: { cellWidth: printType == "Customer" ? 40 : 69 },
                    1: { cellWidth: 10 },
                    2: { cellWidth: 14.5 },
                    3: { cellWidth: 15 },
                    // 4: { cellWidth: 60 },

                },
                // headStyles: {
                //     fillColor: '#2980ba'
                // },
                tableWidth: 'wrap',
                styles: { fontSize: 8 },
                margin: {
                    left: 0.1,
                },
            };
            doc.autoTable(content)

            printType == "Customer" && doc.text(`Sub Total : ${pdfData.subTotal}`, 44, doc.lastAutoTable.finalY + 6)
            printType == "Customer" && doc.text(`Discount : ${pdfData.discount}`, 44, doc.lastAutoTable.finalY + 11)
            printType == "Customer" && doc.text(`Tax : ${pdfData.tax}`, 44, doc.lastAutoTable.finalY + 16)
            printType == "Customer" && doc.text(`Grand Total : ${pdfData.grandTotal}`, 44, doc.lastAutoTable.finalY + 21)
            printType == "Customer" && doc.text(`Thanks For Visiting At "${companyName}"`, 4, doc.lastAutoTable.finalY + 30)
            printType == "Customer" && doc.text(`Powerd By eBeaconsofts`, 13, doc.lastAutoTable.finalY + 35)
            doc.setProperties({
                title: `Recipt Order ${pdfData.number}`,
            });
            doc.autoPrint();

            doc.output('dataurlnewwindow');

            // doc.save(
            //   `Recipt Order ${pdfData.number} ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
            // );
            // doc.save(
            //     `Recipt Order ${pdfData.number}.pdf`
            // );

            // dispatch(getPendingOrder())
        }).catch((error) => {
            dispatch(errorAddOrders());
            console.log(error, "kkkkkkkkkkk")
            handleDangerVisible(isEmpty(error.response.data) ? "Server Error" : error.response.data.message);
        })
    };
};
// extra

//get order
export const requestGetOrders = () => {
    return {
        type: "REQUEST_GET_ORDERS",
    };
};
export const successGetOrders = (data) => {
    return {
        type: "SUCCESS_GET_ORDERS",
        payload: data,
    };
};
export const errorGetAllOrders = () => {
    return {
        type: "ERROR_GET_ORDERS",
    };
};
export const getOrders = () => {
    return (dispatch) => {
        dispatch(requestGetOrders());
        API.get(`/orders?limit=1000&page=0&search=`).then((res) => {
            let data1 = res.data;
            console.log(data1, 'list1');
            dispatch(successGetOrders(data1));
        }).catch((error) => {
            dispatch(errorGetAllOrders());
        })
    };
};
export const requestGetTodayOrders = () => {
    return {
        type: "REQUEST_GET_TODAY_ORDERS",
    };
};
export const successGetTodayOrders = (data) => {
    return {
        type: "SUCCESS_GET_TODAY_ORDERS",
        payload: data,
    };
};
export const errorGetTodayOrders = () => {
    return {
        type: "ERROR_GET_TODAY_ORDERS",
    };
};
export const getTodayOrders = () => {
    return (dispatch) => {
        dispatch(requestGetTodayOrders());
        API.get(`/orders/get_dayopen_orders`).then((res) => {
            let data1 = res.data;
            console.log(data1, 'list1');
            dispatch(successGetTodayOrders(data1));
        }).catch((error) => {
            dispatch(errorGetTodayOrders());
        })
    };
};
//update order
export const UpdateRequestOrders = () => {
    return {
        type: "UPDATE_REQUEST_ORDERS",
    };
};
export const UpdateSuccessOrders = (data) => {
    return {
        type: "UPDATE_SUCCESS_ORDERS",
        payload: data,
    };
};

export const UpdateErrorOrders = () => {
    return {
        type: "UPDATE_ERROR_ORDERS",
    };
};

export const updateOrders = (data, oldData, handleVisible, handleDangerVisible) => {
    return dispatch => {
        dispatch(UpdateRequestOrders());
        console.log(data, oldData, 'hmhm')
        API.put(`/orders/update_order/${oldData._id}`, data)
            .then(res => {
                // dispatch(getOrders());
                dispatch(UpdateSuccessOrders([data]))
                handleVisible()
            }).catch((error) => {
                dispatch(UpdateErrorOrders());
                handleDangerVisible()
            })
    }
};
//delete order
export const DeleteRequestOrders = () => {
    return {
        type: "DELETE_REQUEST_ORDERS",
    };
};
export const DeleteSuccessOrders = (user_id) => {
    return {
        type: "DELETE_SUCCESS_ORDERS",
        user_id: user_id,
    };
};
export const DeleteErrorOrders = () => {
    return {
        type: "DELETE_ERROR_ORDERS",
    };
};

export const deleteOrders = (categoryId) => {
    return dispatch => {
        dispatch(DeleteRequestOrders());
        API.delete(`/orders/${categoryId}`)
            .then(res => {
                // dispatch(getOrders());
                dispatch(DeleteSuccessOrders(categoryId))
            }).catch((error) =>
                dispatch(DeleteErrorOrders()));
    }
};

export const setTest = (h) => {
    return (dispatch) => {
        dispatch(requestCustomersData());
    };
};
//order cancel
export const requestOrderCancel = () => {
    return {
        type: "REQUEST_ORDER_CANCEL",
    };
};
export const successOrderCancel = (data) => {
    return {
        type: "SUCCESS_ORDER_CANCEL",
        payload: data,
    };
};
export const errorOrderCancel = () => {
    return {
        type: "ERROR_ORDER_CANCEL",
    };
}
export const OrderCancel = (orderId, data) => {
    return dispatch => {
        dispatch(requestOrderCancel());
        API.post(`/orders/order_cancelled/${orderId}`, data)
            .then(res => {
                dispatch(getTodayOrders());
                dispatch(successOrderCancel(orderId))
            }).catch((error) =>
                dispatch(errorOrderCancel()));
    }
};
// edit order
export const requestOrderEdit = () => {
    return {
        type: "REQUEST_ORDER_EDIT",
    };
};
export const successOrderEdit = (data) => {
    return {
        type: "SUCCESS_ORDER_EDIT",
        payload: data,
    };
};
export const errorOrderEdit = () => {
    return {
        type: "ERROR_ORDER_EDIT",
    };
}
export const OrderEdit = (orderId) => {
    return dispatch => {
        dispatch(requestOrderEdit());
        API.get(`/orders/order_edit/${orderId}`)
            .then(res => {
                //  dispatch(getOrders());
                dispatch(successOrderEdit(orderId))
            }).catch((error) =>
                dispatch(errorOrderEdit()));
    }
};
//get order by id
export const requestGetOrderById = () => {
    return {
        type: "REQUEST_ORDER_EDIT_DATA",
    };
};
export const successGetOrderById = (data) => {
    return {
        type: "SUCCESS_ORDER_EDIT_DATA",
        payload: data,
    };
};
export const errorGetOrderById = () => {
    return {
        type: "ERROR_ORDER_EDIT_DATA",
    };
}
export const GetOrderById = (orderId) => {
    return dispatch => {
        dispatch(requestGetOrderById());
        API.get(`/orders/get_order_by_id/${orderId}`)
            .then(res => {
                let Data = res.data;
                //  dispatch(getOrders());
                dispatch(successGetOrderById(Data))
            }).catch((error) =>
                dispatch(errorGetOrderById()));
    }
};
//edit addons
export const editOrder = (data) => {
    console.log(data, 'abcd1111')
    return {
        type: "EDIT_ORDER",
        payload: data
    };
};
export const editOrderCooking = (data) => {
    console.log(data, 'abcd1111')
    return {
        type: "EDIT_ORDER_COOKING",
        payload: data
    };
};
export const editOrderCustomer = (data) => {
    console.log(data, 'customer edit')
    return {
        type: "EDIT_ORDER_CUSTOMER",
        payload: data
    };
};
export const editOrderCoupon = (data) => {
    console.log(data, 'COUPON')
    return {
        type: "EDIT_ORDER_COUPON",
        payload: data
    };
};
export const editOrderTotal = (data) => {
    console.log(data, 'Total bill')
    return {
        type: "EDIT_ORDER_TOTAL",
        payload: data
    };
};
export const editOrderDiscount = (data) => {
    console.log(data, 'Total bill')
    return {
        type: "EDIT_ORDER_DISCOUNT",
        payload: data
    };
};
export const editOrderSubTotal = (data) => {
    console.log(data, 'subtotalbill')
    return {
        type: "EDIT_ORDER_SUB_TOTAL",
        payload: data
    };
};
//get pending order
export const requestGetPendingOrder = () => {
    return {
        type: "REQUEST_GET_PENDING_ORDER",
    };
};
export const successGetPendingOrder = (data) => {
    return {
        type: "SUCCESS_GET_PENDING_ORDER",
        payload: data,
    };
};
export const errorGetPendingOrder = () => {
    return {
        type: "ERROR_GET_PENDING_ORDER",
    };
};
export const getPendingOrder = () => {
    return (dispatch) => {
        dispatch(requestGetPendingOrder());
        API.get(`/orders/get_pending_orders?page=0&limit=1000`)
            .then((res) => {
                let data = res.data;
                dispatch(successGetPendingOrder(data));
                console.log("i am in success");
            })
            .catch((error) => {
                dispatch(errorGetPendingOrder());
            });
    };
};
//get complete order
export const requestGetCompleteOrders = () => {
    return {
        type: "REQUEST_GET_COMPLETE_ORDERS",
    };
};
export const successGetCompleteOrders = (data) => {
    return {
        type: "SUCCESS_GET_COMPLETE_ORDERS",
        payload: data,
    };
};
export const errorGetCompleteOrders = () => {
    return {
        type: "ERROR_GET_COMPLETE_ORDERS",
    };
};
export const getCompleteOrders = () => {
    return (dispatch) => {
        dispatch(requestGetCompleteOrders());
        API.get(`/orders/get_complete_orders?limit=1000&page=0&search=`).then((res) => {
            let data1 = res.data;
            console.log(data1, 'list1');
            dispatch(successGetCompleteOrders(data1));
        }).catch((error) => {
            dispatch(errorGetCompleteOrders());
        })
    };
};
//filter product
export const filterType = (data) => {
    console.log(data, "jjjjjjjjjj")
    return {
        type: "FILTER_TYPE",
        payload: data
    };
};
export const filterOrder = (data) => {
    console.log(data, "jjjjjjjjjj")
    return {
        type: "FILTER_ORDER",
        payload: data
    };
};
export const filterOrderByTable = (data) => {
    console.log(data, "jjjjjjjjjj")
    return {
        type: "FILTER_ORDER_BY_TABLE",
        payload: data
    };
};

// //filter by menu
// export const filterItemByMenu = (data) => {
//     console.log(data,"jjjjjjjjjj")
//     return {
//         type: "FILTER_ITEM_BY_MENU",
//         payload: data
//     };
// };