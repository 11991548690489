import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";
import { addCoupons } from "./Coupons.action";
import { setHeading } from "../../../accounts/account.actions";

const AddCoupons = (props) => {
  const [Coupons, setCouponsName] = useState("");
  const [couponsCode, setCouponsCode] = useState();
  const [couponsType, setCouponsType] = useState();
  const [couponsAmount, setCouponsAmount] = useState(0);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [CouponTypeName, setCouponTypeName] = useState();
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadCoupons() {
    props.addCoupons(
      {
        code: couponsCode,
        name: Coupons,
        type: CouponTypeName,
        amount: couponsAmount,
        expiredAt: "2023-03-01T23:59:59.0000000Z",
      },
      handleVisible,
      handleDangerVisible
    );
  }
  let CouponTypeData = [
    {
      CouponType_id: 1,
      CouponType_name: "fixed",
    },
    {
      CouponType_id: 2,
      CouponType_name: "percentage",
    },
  ];
  const handleChangeCouponType = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCouponTypeName(selectedOption.label);
  };
  const CouponTypeList =
    !isEmpty(CouponTypeData) &&
    CouponTypeData.map((x) => {
      let data = { value: x.CouponType_id, label: x.CouponType_name };
      return data;
    });
  useEffect(() => {
    props.setHeading("Add Coupon");
  }, []);
  return (
    <>
      {props.isAddingCoupons ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Enter Coupons Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coupons Name...."
                    onChange={(e) => {
                      setCouponsName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Enter Coupons Code *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coupons code...."
                    onChange={(e) => {
                      setCouponsCode(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Coupon Type *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(CouponTypeData)}
                    placeholder="Select Coupon Type..."
                    onChange={handleChangeCouponType}
                    options={CouponTypeList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* {CouponTypeName=="perc"} */}
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Enter Coupons Amount *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    max={CouponTypeName == "percentage" ? 100 : 99999999999999}
                    value={couponsAmount}
                    placeholder="Coupons Amount...."
                    onChange={(e) => {
                      setCouponsAmount(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {isEmpty(Coupons) || isEmpty(CouponTypeName)||
              isEmpty(couponsCode) || isEmpty(couponsAmount) ? (
                <Button disabled>Save</Button>
              ) : (
                <Button onClick={uploadCoupons}>Save</Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCoupons: (data, handleVisible, handleDangerVisible) =>
      dispatch(addCoupons(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  couponsList: state.CouponReducer.couponsList,
  isAddingCoupons: state.CouponReducer.isAddingCoupons,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCoupons);
