import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Button, FormGroup, Alert, Tab, Tabs } from "react-bootstrap";
import "../add-details.css";
import { ModalBody, Modal } from "reactstrap";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert';
import { getSearchRecivingByDate } from "./Receiving.action";

const ReceivingReportWithDate = (props) => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const exportPDF = () => {
        props.getReceivingByDate({
            startDate: !isEmpty(startDate) ? startDate : "",
            endDate: !isEmpty(endDate) ? endDate : "",
        },
        startDate,
        endDate,
        )

        setStartDate("");
        setEndDate("");
        // navigate('/inventory/Product/RecivingReportDate/TableView')
    }

    const wareHouseData = props.IssuanceList;
    // const [state, setState] = React.useState({
    //     viewColumns: [
    //         { title: 'Issuance Number', field: 'issuanceId' },
    //         { title: 'Requistion', field: 'requsition' },
    //         { title: 'Department', field: 'department' },
    //         { title: 'Issued By', field: 'issuedBy' },
    //         { title: 'Received By', field: 'receivedBy' },
    //         { title: 'Requistion Type', field: 'requsitionType' },
    //     ],
    //     columnsData: [
    //         { title: 'Inventory Code', field: 'inventory', render: rowData => rowData.inventory.warehouse.whKey + "-" + rowData.inventory.category.cateKey + "-" + rowData.inventory.invKey },
    //         { title: 'Inventory Name', field: 'inventory.productName' },
    //         { title: 'Product Quantity', field: 'productQuantity' },
    //     ],
    // });
   
    return (
        <>
            {props.isFetchingReceiving ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col xl="6" lg="6" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>Start Date :</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                            <Col xl="6" lg="6" md="6" sm="6">
                                <FormGroup>
                                    <Form.Label>
                                        <b>End Date</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    >
                                    </Form.Control>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="sendDiv" style={{marginTop:'10px'}}>
                        {isUndefined(startDate) || isUndefined(endDate) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "#1976d2",
                    border: "1px solid #1976d2",
                  }}
                >
                  Generate Receiving Report
                </Button>
              ) : (
                <Button  onClick={() => exportPDF()}>
                  Generate Receiving Report
                </Button>
              )}
                 
                        </div>

                    </div>
                </Container>
            )}

        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
       getReceivingByDate:(data,startDate,endDate)=>dispatch(getSearchRecivingByDate(data,startDate,endDate)),
    };
};
const mapStateToProps = (state, ownProps) => ({
   
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReceivingReportWithDate);