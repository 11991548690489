import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Button, FormGroup, Alert, Tab, Tabs } from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
// import {
//     getPurchase, getApproveDemand, addIssuance, getAllInventory, getIssuance, searchIssuanceReport
// } from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
// import WareHouseModal from "../WareHouseModal";
// import MaterialTable from 'material-table';
// import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@material-ui/icons/Remove";
// import { IconButton } from "@material-ui/core";
import Select from 'react-select';
import Swal from 'sweetalert';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import moment from "moment";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { addDayOpening } from "./Day.action";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconMenu from "../../../drawer/IconMenu";
import { setHeading } from "../../../accounts/account.actions";
const OpeningDay = (props) => {

    const [cash, setCash] = useState('');
    const [resturant, setResturant] = useState(
        {
            id: '',
            label: ''
        }
    );
    console.group('resturant');
    console.log(props.restaurantDataList);
    console.groupEnd('resturant');

    const restaurantList = !isEmpty(props.restaurantDataList) ? props.restaurantDataList.map((x) => (  { value: x._id, label: x.restaurantName } )) : [];
    console.group('resturant');
    console.log(restaurantList);
    console.groupEnd('resturant');
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    function uploadPurchase() {
        props.addDayOpening(
          {
            cashInHand: cash, 
            restaurant: resturant.id
          },
          handleVisible,
          handleDangerVisible
        );
    setCash('');
    setResturant({id:'',label:''})
    }
    // onClick={() => props.addDayOpening({ cashInHand: cash, restaurant: resturant.id }, handleVisible, handleDangerVisible)()}
    const handleVisible = (msg) => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    }
    const [userClick, setUserClick] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    function Logout() {
      window.localStorage.clear();
      window.location.replace("/login");
    }
    useEffect(() => {
        props.getRestaurantData();
        props.setHeading("Day Open")
    }, []);

    return (
        <>
            {props.isFetchingRestaurant ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingRestaurant ? 'Loading...' : 'Loading...'}

                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Date *</b>
                                    </Form.Label>
                                    <Form.Control type="date" placeholder="Date...." name="Date" value={moment(new Date()).format("YYYY-MM-DD")} disabled={true} />
                                    {/* <Select
                                        // placeholder="Select Requistion Type..."
                                        // onChange={(e) => handleChangeRequistion(e)}
                                        // options={getRequistion}
                                    /> */}
                                </Form.Group>
                            </Col>

                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Resturant *</b>
                                    </Form.Label>
                                    {/* <Form.Control type="text" placeholder="Cash...." name="Cash" value={cash} onChange={(e) => setCash(e.target.value)} /> */}
                                    <Select
                                        placeholder="Select Resturant..."
                                        onChange={(e) => setResturant((prev) => ({ ...prev, id: e.value, label: e.label }))}
                                        options={restaurantList}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Cash *</b>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Cash...." name="Cash" value={cash} onChange={(e) => setCash(e.target.value)} />
                               
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="sendDiv">
                            <Button disabled={cash==""||resturant.id==''?true:false} style={{ marginTop: '15px' }} onClick={() => uploadPurchase()} >Day Open</Button>
                        </div>
                    </Form>
                </Container>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRestaurantData: () => dispatch(getRestaurantData()),
        addDayOpening: (data, handleVisible, handleDangerVisible) => dispatch(addDayOpening(data, handleVisible, handleDangerVisible)),
        setHeading:(data)=>dispatch(setHeading(data)),

    };
};
const mapStateToProps = (state, ownProps) => ({

    isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
    restaurantDataList: state.ResturantReducer.restaurantDataList,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OpeningDay);