const initialState = {
  inventoryData: [],
  isFetchinginventoryData: false,
  isFetchingLedgerData: false,
  ledgerlist: [],
  ledgerSearchlist: {},
  isFetchingSearchLedgerData: false
};

const MaterialLegderReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_IVENTORY_DATA":
      return { ...state, isFetchinginventoryData: true };
    case "SUCCESS_IVENTORY_DATA":
      return {
        ...state,
        inventoryData: action.payload,
        isFetchinginventoryData: false,
      };
    case "ERROR_IVENTORY_DATA":
      return { ...state, isFetchinginventoryData: false };

    case "REQUEST_ADD_LEDGER":
      return { ...state, isFetchingLedgerData: true };
    case "SUCCESS_ADD_LEDGER":
      return {
        ...state,
        ledgerlist: action.payload.data,
        isFetchingLedgerData: false,
      };
    case "ERROR_ADD_LEDGER":
      return { ...state, isFetchingLedgerData: false };


    case "REQUEST_ADD_LEDGER_BY_DATE":
      return { ...state, isFetchingSearchLedgerData: true };
    case "SUCCESS_ADD_LEDGER_BY_DATE":
      return {
        ...state,
        ledgerSearchlist: action.payload,
        isFetchingSearchLedgerData: false
      };
    case "ERROR_ADD_LEDGER_BY_DATE":
      return { ...state, isFetchingSearchLedgerData: false };

    default:
      return state;
  }
};
export default MaterialLegderReducer;
