import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find, sumBy, filter } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import "jspdf-autotable";
import Swal from "sweetalert";
import Select from "react-select";
import ImageUploader from "react-images-upload";
import {
  addRecipe,
  editAddons,
  getProductTypeById,
  getProductTypeComplete,
  getProductTypeProduct,
  getRecipeEditById,
  getServiceProduct,
  getVariationById,
  resetRecipeReducer,
} from "./Recipe.action";
import { setHeading } from "../../../accounts/account.actions";
import { SelectAllRounded } from "@material-ui/icons";
import { resetReducer } from "../AddProduct/Product.action";
const AddRecipe = (props) => {
  console.log("k1")
  const [TypeId, setTypeId] = useState("");
  const [Typename, setTypeName] = useState("");
  const [variationId, setvariationId] = useState(0);
  const [variationname, setvariationName] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const selectRef = useRef();

  const handleChangeType = (selectedOption) => {
    console.log(selectedOption, "sop");
    setTypeId(selectedOption.value);
    setTypeName(selectedOption.label);
    props.getProductTypeByID(selectedOption.value);
  };

  const gettypeList =
    !isEmpty(props.serviceProductList) &&
    props.serviceProductList.map((x) => {
      let data = { value: x._id, label: x.name };
      return data;
    });
  const handleChangeVariation = (selectedOption) => {
    console.log(selectedOption, "sop");
    setvariationId(selectedOption.value);
    setvariationName(selectedOption.label);
    props.getRecipe({
      productVariationId: selectedOption.value,
      product: TypeId,
    });
  };
  const getVariationList =
    !isEmpty(props.productTypeListById) &&
    // console.log(props.productTypeListById[0].variation,"tttttttttttttt")
    props.productTypeListById[0].variation.map((x) => {
      let data = { value: x._id, label: `${x.flavorLabel} / ${x.sizeLabel}` };
      return data;
    });
    console.log(props.productcom,"lllllllllll")
    const getMList1 =
      !isEmpty(props.productcom) &&
      props.productcom.map((x) => {
        console.log(x.data._id,"kkkkkkkkk")
        let d=x.data
        console.log(d,"kkkkkkkkk123")
        let data = {
          value: d._id,
          label: d.name,
          label1: d.uomLabel,
          labelPrice:d.salePrice,
          value1: "",
        };
        return data;
      // })
      });
      console.log(getMList1,"testttttt")
  const getMList =
  console.log(props.productTypeListProduct,"ttttttttttttt")
    !isEmpty(props.productTypeListProduct.allData) &&
    props.productTypeListProduct.allData.map((x) => {
      console.log(x,"kkkkkkkkk")
      let data = {
        value: x.data._id,
        label: x.data.name,
        label1: x.data.uomLabel,
        labelPrice:x.data.salePrice,
        value1: "",
      };
      return data;
    // })
    });
    console.log(getMList,"ttttttttttttt22")
  let getRecipeList =
    !isEmpty(props.variationListById) &&
    props.variationListById[0].recipeProducts.map((x) => {
      let data = {
        value: x._id,
        label: x.recipeProductName,
        labelQ: x.quantity,
        lableprice: x.price,
        lableuom: x.uomName,
        uomPrice:x.uomPrice,
      };
      return data;
    });
  // const getRecipeListEdit =
  // !isEmpty(props.recipeEdit) &&
  // props.recipeEdit[0].recipeProducts.map((x) => {
  //   let data = {
  //     value: x._id,
  //     label: x.recipeProductName,
  //     labelQ: x.quantity,
  //     lableprice: x.price,
  //     lableuom: x.uomName,
  //   };
  //   return data;
  // });
  console.log(getRecipeList,props.recipeEdit, "check");
  const [inputField1, setInputField1] = useState([
    {
      product: 0,
      recipeProductName: "",
      uom: "",
      uomName: "",
      uomPrice:0,
      quantity: 0,
      price: 0,
    },
  ]);

  //hadle for add fuctio
  const handleAddInput1 = () => {
    setInputField1([
      ...inputField1,
      {
        product: 0,
        recipeProductName: "",
        uom: "",
        uomName: "",
        uomPrice:0,
        quantity: 0,
        price: 0,
      },
    ]);
  };
  //remove for add fuctio
  const handleRemoveInput1 = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    setInputField1(list);
  };
  const handleChange1 = (e, index, type) => {
    //  let z = find(props.allInventory, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField1];
    console.log(list,e,"lllllll")
    if (type == "master") list[index]["product"] = e.value;
    if (type == "master") list[index]["recipeProductName"] = e.label;
    console.log(e.label1,e.labelPrice, "hhhhh");
    if (type == "master") list[index]["uom"] = e.value1;
    if (type == "master") list[index]["uomName"] = e.label1;
    if (type == "master") list[index]["uomPrice"] = Number( e.labelPrice);
    if (type == "quantity") list[index]["quantity"] = Number(e.target.value);
    // if (type == "quantity") list[index]["quantityLabel"] = e.label;
    // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
   // if (type == "quantity") list[index]["quantity"]=let damyprice=Number(e.target.value)*Number( e.labelPrice);
    console.log( (Number(list[index]["uomPrice"])*Number(list[index]["quantity"])),"uuuuuuu")
    if (type == "quantity") list[index]["price"] = Number(e.target.value)*list[index]["uomPrice"];//(Number(list[index]["uomPrice"])*Number(list[index]["quantity"]));
    console.log(list[index]["price"],"kkkkkk")
    setInputField1(list);
  };
  const handleAddInputupdate = () => {
    props.EditRecipe([
      ...props.recipeEdit[0].recipeProducts,
      {
        quantity: 0,
        recipeProductName: "",
        price: 0,
        uomName: "",
        uomPrice:0,
        uom: "",
      },
    ]);
      props.editRecipeItem({recipeProducts:props.recipeEdit[0].recipeProducts},props.recipeEdit[0]._id,handleVisible,handleDangerVisible)
  };
  console.log(props.recipeList,"testttt")
  const handleRemoveInputupdate = (index) => {
    console.log(props.recipeEdit,"hhhhhh")
    const list = [...props.recipeEdit[0].recipeProducts];
    list.splice(index, 1);
    props.EditRecipe(list);
    props.editRecipeItem({recipeProducts:list},props.recipeEdit[0]._id,handleVisible,handleDangerVisible);
  };
  const handleChangeupdate = (e, index, type) => {
    const { name, value } = e;
    const list = [...props.recipeEdit[0].recipeProducts];
    console.log(list, "bis");
    if (type == "master") list[index]["product"] = e.value;
    if (type == "master") list[index]["recipeProductName"] = e.label;
    console.log(e.label1, "hhhhh");
    if (type == "master") list[index]["uom"] = e.value1;
    if (type == "master") list[index]["uomName"] = e.label1;
    if (type == "master") list[index]["uomPrice"] = e.labelPrice;
    props.EditRecipe(list);
    console.log(props.recipeEdit, "bis2");
    if (type == "quantity") list[index]["quantity"] = Number(e.target.value);
    props.EditRecipe(list);
    props.editRecipeItem({recipeProducts:props.recipeEdit[0].recipeProducts},props.recipeEdit[0]._id,handleVisible,handleDangerVisible);
    if (type == "quantity") list[index]["price"] = Number(e.target.value)*list[index]["uomPrice"];//Number(quantity)*Number(uomPrice);
    props.EditRecipe(list);
    props.editRecipeItem({recipeProducts:props.recipeEdit[0].recipeProducts},props.recipeEdit[0]._id,handleVisible,handleDangerVisible);
  };
  function uploadProduct() {
    props.addRecipe(
      {
        recipeProducts:inputField1,
        product: TypeId,
        productVariationName: variationname,
        productVariationId: variationId,
      },
      handleVisible,
      handleDangerVisible
    );
    setvariationId(0);
    setvariationName(null);
    setTypeId(null);
    setTypeName(null);
    // clearValue();
    setInputField1([{
      
        product: 0,
        recipeProductName: "",
        uom: "",
        uomName: "",
        uomPrice:0,
        quantity: 0,
        price: 0,
      
    }])
  }
  function uploadProductUpdate() {
    props.editRecipeItem({recipeProducts:props.recipeEdit[0].recipeProducts},props.recipeEdit[0]._id,handleVisible,handleDangerVisible);
   setInputField1(
      {
        product: 0,
        recipeProductName: "",
        uom: "",
        uomName: "",
        uomPrice:0,
        quantity: 0,
        price: 0,
      }
    );
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
 
  // const clearValue = () => {
  //   // console.log( selectRef.current.select.clearValue(), "sr")
  //   // selectRef.current.select.clearValue()
  // };

  console.log(getRecipeList, "eeeeeee111")
console.log(props.recipeEdit, "eeeeeee1112")
useEffect(() => {
  props.getServiceProduct();
  // props.getRecipe({
  //   productVariationId: "",
  //   product: "",
  // })
  props.reset()
  props.getMatrial();
  props.setHeading("Add Recipe");
  setInputField1([{
    
    product: 0,
    recipeProductName: "",
    uom: "",
    uomName: "",
    uomPrice:0,
    quantity: 0,
    price: 0,
  
}])
}, []);
  return (
 <>
     { props.isFetchingProductTypeProduct ||
     props.isFetchingEditRecipe ||
      props.isFetchingServiceProduct ||
      props.isFetchingvariationById ||
      props.isAddingRecipe? (
        <div className="loader-div">
          <Button variant="info" disabled>
          <Spinner
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="info"
          />
          {props.isFetchingProductTypeProduct||props.isFetchingServiceProduct||props.isFetchingvariationById ? 'Loading...' : 'Saving...'}
          </Button>
        </div>)
      : 
        <Container fluid>
          <div className="main">
      
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      {console.log("eeeeeeee")}
                      <Form.Label>
                        <b>Select Products *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.serviceProductList)}
                        placeholder="Select Product..."
                        onChange={handleChangeType}
                        options={gettypeList}
                        value={{label:Typename}}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Variation *</b>
                      </Form.Label>
                      <Select
                        // ref={selectRef}
                        isDisabled={isEmpty(TypeId)||isEmpty(getVariationList)}
                        placeholder="Select Variation..."
                        onChange={handleChangeVariation}
                        options={getVariationList}
                        value={{label:variationname}}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12" md="12" sm="12">
                    <Form.Group>
                      <Form.Label>
                        <b>Recipe Generation</b>
                      </Form.Label>
                      </Form.Group>
</Col>
</Row>
                      {!isEmpty(getRecipeList) &&!isEmpty(props.recipeEdit) && getRecipeList ?
                       (
                        <>
                       { props.recipeEdit[0].recipeProducts.map((item, i) => {
                          console.log(item, i, "edit");
                          return (
                            <React.Fragment key={i}>
                              <Row 
                                style={{
                                  marginLeft: "25px",
                                  marginRight: "25px",
                                  
                                }}
                              >
                                <Col lg="3" md="3" sm="3">
                                  <Form.Group>
                                    <Form.Label>
                                      <b> Select Material *</b>
                                    </Form.Label>
                                    <Select 

                                      style={{ zIndex: 1 }}
                                      // isDisabled={variationId==0||isEmpty(getMList)}
                                      value={{ label: item.recipeProductName }}
                                      placeholder="Select Items..."
                                      name="master"
                                      onChange={(e) =>
                                        handleChangeupdate(e, i, "master")
                                      }
                                      options={getMList1}
                        
                                      //  isOptionDisabled={(option) => (console.log(option,getMList,"frfrfr") , !isEmpty(find(getMList, x => x.value == option.value
                                      //  )))}
                                     
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg="2" md="2" sm="2">
                                  <Form.Group className="mb-3">
                                    <Form.Label>
                                      <b>Uom *</b>
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Uom...."
                                      name="uom"
                                      disabled={true}
                                      value={item.uomName}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg="2" md="2" sm="2">
                                  <Form.Group className="mb-3">
                                    <Form.Label>
                                      <b>Quantity *</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="Add Price...."
                                      name="quantity"
                                      value={item.quantity}
                                      onChange={(e) =>
                                        handleChangeupdate(e, i, "quantity")
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg="2" md="2" sm="2">
                                  <Form.Group className="mb-3">
                                    <Form.Label>
                                      <b>Price *</b>
                                    </Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="Add Price...."
                                      name="price"
                                      disabled={true}
                                      value={item.price}
                                      onChange={(e) =>
                                        handleChangeupdate(e, i, "price")
                                      }
                                    />
                                  </Form.Group>

                                </Col>
                                <Col
                                    lg="3"
                                    md="3"
                                    sm="3"
                                   style={{ marginTop: "2.5%" }}
                                  >
                                    {/* <h1>Afraz</h1> */}
                                    <Form.Group>
                                      {props.recipeEdit[0].recipeProducts.length - 1 == i && (
                                        <Button
                                        title="Click to Add Demand"
                                          onClick={handleAddInputupdate}
                                          style={{
                                            color:'white',
                                            float: "right",
                                            
                                          }}
                                        >
                                          <AddIcon/>
                                        </Button>
                                        // <IconButton
                                        //   title="Click to Add Demand"
                                        //   onClick={handleAddInput1}
                                        //   style={{
                                        //    // float: "right",
                                        //     color: "black",
                                        //   }}
                                        // >
                                        //   <AddIcon />
                                       // </IconButton>
                                      )}

                                      {props.recipeEdit[0].recipeProducts.length !== 1 && (
                                        <Button
                                            title="Click to Remove Demand"
                                          onClick={() => handleRemoveInputupdate(i)}
                                          style={{
                                            float: "right",
                                            color: "white",
                                            marginRight:'2%',
                                          }}
                                        >
                                          <RemoveIcon/>
                                        </Button>
                                        // <IconButton
                                        //   title="Click to Remove Demand"
                                        //   onClick={() => handleRemoveInput1(i)}
                                        //   style={{
                                        //  //   float: "right",
                                        //     color: "black",
                                        //   }}
                                        // >
                                        //   <RemoveIcon />
                                        // </IconButton>
                                      )} 
                                        </Form.Group> 
                                  </Col>
                                {/* <Col
                                  lg="3"
                                  md="3"
                                  sm="3"
                                  style={{ marginTop: "25px" }}
                                >
                                  <Form.Group>
                                    {props.recipeEdit.length - 1 == i && (
                                      <IconButton
                                        title="Click to Add Demand"
                                        onClick={handleAddInputupdate}
                                        style={{
                                          float: "right",
                                          color: "black",
                                        }}
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    )}

                                    {props.recipeEdit.length !== 1 && (
                                      <IconButton
                                        title="Click to Remove Demand"
                                        onClick={() =>
                                          handleRemoveInputupdate(i)
                                        }
                                        style={{
                                          float: "right",
                                          color: "black",
                                        }}
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                    )}
                                  </Form.Group>
                                </Col> */}
                              </Row>
                            </React.Fragment>
                          );})
                          }
                              <div className="sendDiv">
                { isEmpty(props.recipeEdit[0].recipeProducts) ? (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "#1976d2",
                        border: "1px solid #1976d2",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button  onClick={uploadProductUpdate}>
                      Save
                    </Button>
                  )}
                </div>
                          </>
                          )
                        : (
                        <>
                          {inputField1.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                <Row
                                  style={{
                                    marginLeft: "25px",
                                    marginRight: "25px",
                                  }}
                                >
                                  <Col lg="3" md="3" sm="3">
                                    <Form.Group>
                                      <Form.Label>
                                        <b> Select Material *</b>
                                      </Form.Label>
                                      <Select
                                        style={{ zIndex: 1 }}
                                        value={{
                                          label: item.recipeProductName,
                                        }}
                                        placeholder="Select Items..."
                                        name="master"
                                        onChange={(e) =>
                                          handleChange1(e, i, "master")
                                        }
                                        options={getMList1}
                                        
                                       isOptionDisabled={(option) => (console.log(option,inputField1), !isEmpty(find(inputField1, x => x.product == option.value)))}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="2" md="2" sm="2">
                                    <Form.Group className="mb-3">
                                      <Form.Label>
                                        <b>Uom *</b>
                                      </Form.Label>
                                      <Form.Control
                                        placeholder="Uom...."
                                        name="uom"
                                        disabled={true}
                                        value={item.uomName}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="2" md="2" sm="2">
                                    <Form.Group className="mb-3">
                                      <Form.Label>
                                        <b>Quantity *</b>
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        placeholder="Add Price...."
                                        name="quantity"
                                        value={item.quantity}
                                        onChange={(e) =>
                                          handleChange1(e, i, "quantity")
                                        }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="2" md="2" sm="2">
                                    <Form.Group className="mb-3">
                                      <Form.Label>
                                        <b>Price *</b>
                                      </Form.Label>
                                      <Form.Control
                                        type="number"
                                        placeholder="Add Price...."
                                        name="price"
                                        disabled={true}
                                        value={item.price}
                                        // onChange={(e) =>
                                        //   handleChange1(e, i, "price")
                                        // }
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col
                                    lg="3"
                                    md="3"
                                    sm="3"
                                   style={{ marginTop: "2.5%" }}
                                  >
                                    {/* <h1>Afraz</h1> */}
                                    <Form.Group>
                                      {inputField1.length - 1 == i && (
                                        <Button
                                        title="Click to Add Demand"
                                          onClick={handleAddInput1}
                                          style={{
                                            color:'white',
                                            float: "right",
                                            
                                          }}
                                        >
                                          <AddIcon/>
                                        </Button>
                                        // <IconButton
                                        //   title="Click to Add Demand"
                                        //   onClick={handleAddInput1}
                                        //   style={{
                                        //    // float: "right",
                                        //     color: "black",
                                        //   }}
                                        // >
                                        //   <AddIcon />
                                       // </IconButton>
                                      )}

                                      {inputField1.length !== 1 && (
                                        <Button
                                            title="Click to Remove Demand"
                                          onClick={() => handleRemoveInput1(i)}
                                          style={{
                                            float: "right",
                                            color: "white",
                                            marginRight:'2%',
                                          }}
                                        >
                                          <RemoveIcon/>
                                        </Button>
                                        // <IconButton
                                        //   title="Click to Remove Demand"
                                        //   onClick={() => handleRemoveInput1(i)}
                                        //   style={{
                                        //  //   float: "right",
                                        //     color: "black",
                                        //   }}
                                        // >
                                        //   <RemoveIcon />
                                        // </IconButton>
                                      )} 
                                        </Form.Group> 
                                  </Col>
                                </Row>
                              </React.Fragment>
                            );
                          })}{" "}
                           <div className="sendDiv">
                { isEmpty(TypeId)||isEmpty(variationId)||isEmpty(inputField1[0].product)||inputField1[0].quantity==0 ? (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        // backgroundColor: "#1976d2",
                        border: "1px solid #1976d2",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button  onClick={uploadProduct}>
                      Save
                    </Button>
                  )}
                </div>
                        </>
                      )}
            
                
               
              </Form>
           
          </div>
        </Container>
       }</>);
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRecipe: (data, handleVisible, handleDangerVisible) => dispatch(addRecipe(data, handleVisible, handleDangerVisible)),
    getServiceProduct: () => dispatch(getServiceProduct()),
    getProductTypeByID: (data) => dispatch(getProductTypeById(data)),
    getMatrial: () => dispatch(getProductTypeComplete()),
    getRecipe: (data) => dispatch(getVariationById(data)),
    EditRecipe: (data) => dispatch(editAddons(data)),
    reset: () => dispatch(resetRecipeReducer()),
    editRecipeItem:(data,id,handleVisible,handleDangerVisible)=>dispatch(getRecipeEditById(data,id,handleVisible,handleDangerVisible)),
    setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productcom:state.RecipeReducer.productcom,
  serviceProductList: state.RecipeReducer.serviceProductList,
  recipeEdit: state.RecipeReducer.recipeEdit,
  productTypeListProduct: state.RecipeReducer.productTypeListProduct,
  productTypeListById: state.RecipeReducer.productTypeListById,
  isFetchingServiceProduct: state.RecipeReducer.isFetchingServiceProduct,
  isFetchingProductTypeProduct:state.RecipeReducer.isFetchingProductTypeProduct,
  isFetchingProductTypeById: state.RecipeReducer.isFetchingProductTypeById,
  variationListById: state.RecipeReducer.variationListById,
  isFetchingvariationById:state.RecipeReducer.isFetchingvariationById,
  isFetchingEditRecipe:state.RecipeReducer.isFetchingEditRecipe,
  isAddingRecipe:state.RecipeReducer.isAddingRecipe,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddRecipe);
