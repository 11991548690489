import API from "../../../../../global/api";

export const requestAddUom = () => {
    return {
        type: "REQUEST_ADD_UOM",
    };
};
export const successAddUom = (data) => {
    return {
        type: "SUCCESS_ADD_UOM",
        payload: data,
    };
};
export const errorAddUom = () => {
    return {
        type: "ERROR_ADD_UOM",
    };
};

export const addUom = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddUom());
        API.post(`/uom`, data).then((res) => {
           // dispatch(getUomData());
            dispatch(successAddUom(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddUom());
            handleDangerVisible(error.response.data.message);
        })
    };
};
// export const DeleteRequestUom = () => {
//     return {
//         type: "DELETE_REQUEST_UOM",
//     };
// };
// export const DeleteSuccessUom = (user_id) => {
//     return {
//         type: "DELETE_SUCCESS_UOM",
//         user_id: user_id,
//     };
// };
// export const DeleteErrorUom = () => {
//     return {
//         type: "DELETE_ERROR_UOM",
//     };
// };
// export const deleteUom = (vendorId) => {
//     return dispatch => {
//         dispatch(DeleteRequestUom());
//         API.delete(`/hall/${vendorId}`)
//             .then(res => {
//                // dispatch(getUomData());
//                 dispatch(DeleteSuccessUom(vendorId))
//             }).catch((error) =>
//             {
//                 dispatch(DeleteErrorUom())
//     });
//     }
// };
// export const UpdateRequestUom = () => {
//     return {
//         type: "UPDATE_REQUEST_UOM",
//     };
// };
// export const UpdateSuccessUom = (data) => {
//     return {
//         type: "UPDATE_SUCCESS_UOM",
//         payload: data,
//     };
// };

// export const UpdateErrorUom = () => {
//     return {
//         type: "UPDATE_ERROR_UOM",
//     };
// };

// export const updateUom = (data, oldData) => {
//     return dispatch => {
//         dispatch(UpdateRequestUom());
//         console.log(data,'hmhm')
//         API.put(`/hall/${oldData._id}`,
//             {
//                 hallName: data.hallName,
//                 hallLocation:data.hallLocation,
//                 hallDesc:data.hallDesc     
//             },{
//         })
//             .then(res => {
//                // dispatch(getUomData());
//                 dispatch(UpdateSuccessUom([data]))
//             }).catch((error) =>
//                 dispatch(UpdateErrorUom()));
//     }
// };
