import {
    Container,
  } from "react-bootstrap";
  // import "./add-details.css";
  import { connect } from "react-redux";
  import MaterialFlavors from 'material-table';
  import Spinner from "react-bootstrap/Spinner";
  import { baseURL ,headers} from "../../../../../global/api";

  import { setHeading } from "../../../accounts/account.actions";
  import { useEffect } from "react";
import { getReceiving } from "./Receiving.action";
import MaterialTable from "material-table";
  const ReceivingList = (props) => {
    useEffect(() => {
       // props.getReciving();
      props.setHeading("Receiving List");
      console.log(props.ReceivingList, "uuuuuuuu");
    }, []);
    return (
      <>
      {console.log(props.ReceivingList, "uuuuuuuu")}
        {props.isFetchingReceiving ? (
          <div className="loader-div">
            <Spinner
              variant="primary"
              animation="grow"
              size="xl"
              role="status"
              aria-hidden="true"
            />
          </div>
        ) : (
          <Container fluid>
          <div className="main">
          <MaterialFlavors
                    title="GRN Details"
                    columns={[
                      // { title: 'Flavors Id', field: 'table_id', editable: 'never' },
                       { title: 'Receiving Date', field: 'receivingDate' },
                       { title: 'Restaurant Name', field: 'restaurantId.restaurantName' },
                       { title: 'Receivied By', field: 'receivedBy' },
                       { title: 'Receiving Description', field: 'recivingDescription' },
                     ]}
                     data={(query) =>
                      new Promise((resolve, reject) => {
                          let url = `${baseURL}/receiving?`;
                          url += "limit=" + query.pageSize;
                          url += "&page=" + (query.page);
                          url += "&search=" + query.search;
                          fetch(url, {
                              method: 'GET',
                              headers: headers,
                          })
                              .then((response) => response.json())
                              .then((result) => {
                                  resolve({
                                      data: result.data,
                                      page: result.page,
                                      totalCount: result.total_results,
                                  });
                              });
  
                      })
                  }
                  detailPanel={rowData => {
                    return (
                        <MaterialTable
                            //columns={state.columnsData}
                            columns={[
                                { title: 'Product Name ', field: 'ProductName' },
                                { title: 'Available Quantity', field: 'availableProductQuantity' },
                                { title: 'Add Quantity', field: 'requiredProductQuantity' },
                            ]}
                            data={rowData.receivingData}
                            // data={(query) =>
                            //     new Promise((resolve, reject) => {
                            //         let url = `${baseURL}/receiving?`;
                            //         url += "limit=" + query.pageSize;
                            //         url += "&page=" + (query.page);
                            //         url += "&search=" + query.search;
                            //         fetch(url, {
                            //             method: 'GET',
                            //             headers: headers,
                            //         })
                            //             .then((response) => response.json())
                                        
                            //             .then((result) => {
                            //                 let a=-1;
                            //                 resolve({
                                                
                            //                     data: result.data[a+1].receivingData,
                            //                     page: result.page,
                            //                     totalCount: result.total_results,
                            //                 });
                            //             });
                                        
                            //     })
                            // }                            
                            options={{
                                actionsColumnIndex: -1,
                                toolbar: false,
                                exportButton: true,
                                sorting: true,
                                paging: true,
                                pageSize: 200,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [50, 100, 150, 200],    // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}

                        />
                    )
                }}
                    options={{
                      actionsColumnIndex: -1,
                     // filtering: true,
                      exportButton: true,
                      exportAllData: true,
                      paging: true,
                      pageFlavors: 50,       // make initial page flavors
                      emptyRowsWhenPaging: false,   // To avoid of having empty rows
                      pageFlavorsOptions: [30, 50,100,150,200],    // rows selection options
                      headerStyle: {
                        position: 'sticky', top: 0,
                        color: '#00BBBB',
                        fontWeight: '550',
                        onRowAdd: 'none',
                      },
  
                    }}
                    // onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    // editable={{
                    //    onRowUpdate: (newData, oldData) =>
                    //      new Promise((resolve) => {
                    //        setTimeout(() => {
                    //          resolve();
                    //          {
                    //            props.updateFlavors(newData, oldData)
                    //          }
                    //        }, 600);
                    //      }),
                    //   onRowDelete: (oldData) =>
                    //     new Promise((resolve) => {
                    //       setTimeout(() => {
                    //         resolve();
                    //         props.deleteFlavors(oldData._id);
                    //       }, 600);
                    //     }),
                    // }}
                  />
                  </div>
                  </Container>
                  )}
                  </>
    );
  };
  const mapDispatchToProps = (dispatch) => {
    return {
      getReciving: () => dispatch(getReceiving()),
    //   deleteFlavors: (tableId) => dispatch(deleteFlavors(tableId)),
    //   updateFlavors: (newData, oldData) => dispatch(updateFlavors(newData, oldData)),
      setHeading:(data)=>dispatch(setHeading(data)),
    };
  };
  const mapStateToProps = (state, ownProps) => ({
    ReceivingList:state.RecivingReducer.ReceivingList,
    isFetchingReceiving:state.RecivingReducer.isFetchingReceiving,
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(ReceivingList);
  