import API from "../../../../../global/api";
import { get, isEmpty } from "lodash";
import jsPDF from "jspdf";
import moment from "moment";
import { Link } from "react-router-dom";
import { sumBy } from "lodash";

//  Get Pending Order

export const requestGetPendingOrder = () => {
  return {
    type: "REQUEST_GET_PENDING_ORDER",
  };
};
export const successGetPendingOrder = (data) => {
  return {
    type: "SUCCESS_GET_PENDING_ORDER",
    payload: data,
  };
};
export const errorGetPendingOrder = () => {
  return {
    type: "ERROR_GET_PENDING_ORDER",
  };
};
export const getPendingOrder = () => {
  return (dispatch) => {
    dispatch(requestGetPendingOrder());
    API.get(`/orders/get_pending_orders?page=0&limit=1000`)
      .then((res) => {
        let data = res.data;
        dispatch(successGetPendingOrder(data));
        console.log("i am in success");
      })
      .catch((error) => {
        dispatch(errorGetPendingOrder());
      });
  };
};
//update status
export const UpdateRequestStatus = () => {
  return {
    type: "UPDATE_REQUEST_STATUS",
  };
};
export const UpdateSuccessStatus = (data) => {
  return {
    type: "UPDATE_SUCCESS_STATUS",
    payload: data,
  };
};

export const UpdateErrorStatus = () => {
  return {
    type: "UPDATE_ERROR_STATUS",
  };
};
export const updateStatus = (
  data,
  status,
  taxAmount,
  afterTaxTotalAmount,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(UpdateRequestStatus());
    console.log(data, "hmhm11");
    API.post(
      `/orders/update_order_status`,
      {
        _id: data._id,
        status: status,
        tax:taxAmount,
        grandTotal:afterTaxTotalAmount
      },
      {}
    )
      .then((res) => {
        // dispatch(getTableData());
        dispatch(UpdateSuccessStatus([data]));
        handleVisible();
        
      })
      .catch((error) => {
    dispatch(UpdateErrorStatus());
    handleDangerVisible();}
      )
  };
};
export const filterOrderKds = (data) => {
  console.log(data,"jjjjjjjjjj")
  return {
      type: "FILTER_ORDER",
      payload: data
  };
};
export const filterOrderByTableKds= (data) => {
  console.log(data,"jjjjjjjjjj")
  return {
      type: "FILTER_ORDER_BY_TABLE",
      payload: data
  };
};

