import React, { useEffect } from "react";
import { Container, Form, Row, Col, Button, FormGroup, Navbar, Image } from "react-bootstrap";

import Select from "react-select";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Accordion from "react-bootstrap/Accordion";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import { style } from "@mui/system";
import AppBar from "@mui/material/AppBar";
import { Link } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Spinner from "react-bootstrap/Spinner";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import PrintIcon from "@mui/icons-material/Print";
import IconMenu from "../../../drawer/IconMenu";
import { useState } from "react";
import swal from "sweetalert";
import moment from "moment";
import { isEmpty } from "lodash";
import Carousel from 'react-bootstrap/Carousel';
import InventoryPic from "../../../../../Images/Inventory.jpg";
import backupLogo from "../../../../../Images/backupLogo.jpg";
import AccountsPic from "../../../../../Images/Accounts.jpg";
import TablePic from "../../../../../Images/Table Management.jpg";
import OrderPic from "../../../../../Images/view order.jpg";
import kdsPic from "../../../../../Images/kds.jpg";
import PosPic from "../../../../../Images/posLogo.jpg"
import MasterControlPic from "../../../../../Images/Master Control.jpg"
import ReportPic from "../../../../../Images/report.jpg"
import backgroundImage from "../../../../../Images/MAIN BACK.jpg"
function WebOrderView(props) {


    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    //   const [search, setSearch] = useState("");
    //   const [searchTable, setSearchTable] = useState("");
    //   const playAudio = () => {
    //     new Audio(audio).play();
    //   };
    var items = [
        {
            name: "Random Name #1",
            description: "Probably the most random thing you have ever seen!",
            url: 'https://www.chanchao.com.tw/vietnamwood/images/default.jpg'

        },
        {
            name: "Random Name #2",
            description: "Hello World!",
            url: 'https://www.chanchao.com.tw/vietnamwood/images/default.jpg'
        },
        {
            name: "Random Name #2",
            description: "Hello World!",
            url: 'https://www.chanchao.com.tw/vietnamwood/images/default.jpg'
        },
        {
            name: "Random Name #2",
            description: "Hello World!",
            url: 'https://www.chanchao.com.tw/vietnamwood/images/default.jpg'
        }
    ]

    const handleVisible = () => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        swal({
            title: "Success",
            text: "Order Status update Successfully",
            icon: "success",
            button: "Ok",
            timer: 3000,
        });
    };

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        swal({
            title: "Error",
            text: "Something went Wrong!",
            icon: "error",
            button: "Ok",
        });
    };
    const image = []

    useEffect(() => {

    }, []);
    return (
        <Container fluid>
            <div >
                <Navbar style={{ backgroundColor: '#81abd3' }}  >
                    <Container>
                        <Navbar.Brand style={{ color: 'white' }}><b>Web View</b></Navbar.Brand>
                        <IconMenu />
                    </Container>
                </Navbar>
            </div>

            <Carousel >
                {items.map((item, i) =>
                    <Carousel.Item interval={2000}>
                        <Image src='https://source.unsplash.com/random' width={'100%'} height={'500px'} />
                        {/* <Carousel.Caption>
          <h3>{i}</h3>
        </Carousel.Caption> */}
                    </Carousel.Item>
                )}
            </Carousel>
        </Container>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {

    };

};
const mapStateToProps = (state, ownProps) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(WebOrderView);
