import React, { useEffect, useState, forwardRef } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  Modal,
  Alert,
} from "react-bootstrap";
import "../add-details.css";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";
import {
  addCategoryImage,
  addCategoryName,
  resetReducerCategory,
} from "./Catagory.action";
import { setHeading } from "../../../accounts/account.actions";
const AddCategory = (props) => {
  const [category, setCategoryName] = useState();
  const [categoryCode, setCategoryCode] = useState();
  const [wareHouseId, setwareHouseId] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [uploadImage, setUploadImage] = useState([]);
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const onDrop = (pictureFiles, pictureDataURLs) => {
    console.log(pictureFiles, pictureDataURLs, "yyy");
    props.categoryImage({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  function uploadCategory() {
    props.addCategoryName(
      {
        name: category,
        image: props.categoryImageURL.file,
      },
      handleVisible,
      handleDangerVisible
    );
    setCategoryName("");
    props.reset();
  }
  useEffect(() => {
    props.setHeading("Add Menu");
    // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isAddingCategory ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            {/*   <Row>
                            <Col xl="12" lg="12" md="12" sm="12">
                                <Form.Label>
                                    <b> Select Warehouse </b>
                                </Form.Label>
                                <Select
                                    placeholder="Select WareHouse..."
                                    onChange={setwareHouseId}
                                    options={getWareHouseList}
                                />
                            </Col>
                        </Row>*/}
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Menu Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Menu Name...."
                    onMouseLeave={(e) => setCategoryName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b style={{ fontSize: "large" }}>Upload Image</b>
                  </Form.Label>
                  <ImageUploader
                    withIcon={false}
                    buttonText="Upload Image"
                    onChange={onDrop}
                    // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    maxFileSize={999999999999999999999}
                    singleImage={true}
                    withPreview={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv">
              {isEmpty(category) ? (
                <Button disabled>
                  Save
                </Button>
              ) : (
                <Button  onClick={uploadCategory}>
                  Save
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => dispatch(resetReducerCategory),
    categoryImage: (data) => dispatch(addCategoryImage(data)),
    addCategoryName: (data, handleVisible, handleDangerVisible) =>
      dispatch(addCategoryName(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  dataAddedCategory: state.CategoryReducer.dataAddedCategory,
  dataNotAddedCategory: state.CategoryReducer.dataNotAddedCategory,
  isAddingCategory: state.CategoryReducer.isAddingCategory,
  categoryImageURL: state.CategoryReducer.categoryImageURL,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);
