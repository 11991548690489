import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert, Tabs, Tab } from "react-bootstrap";
import "./add-levels.css";
import {getLevel3Data } from "./accountsLevel.action";
import { connect } from "react-redux";
import { isEmpty, get, map, isNull, find, groupBy, sortBy } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import ReactExport from "react-export-excel";
import PDFLevel3 from "./PDFLevel3";
import { PDFDownloadLink } from '@react-pdf/renderer';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Level3Report = (props) => {
      const level3Data = props.level3List;
    useEffect(() => {
        props.getLevel3Data();
    }, []);
    return (
        <>
                    {props.isFetchinglevel3List ? (
                        <div className="loader-div">
                            <Button variant="info" disabled>
                                <Spinner
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="info"
                                />

                            </Button>
                        </div>
                    ) : (
                        <Container fluid>
                            <div className="main">

                                <ExcelFile element={<button className="sendButton" style={{ marginRight: "5%" }}>Generate Excel</button>}>
                                    <ExcelSheet data={map(level3Data, lev => Object.assign({}, ...function flatten(o) { return [].concat(...Object.keys(o).map(k => typeof o[k] === 'object' ? flatten(o[k]) : ({ [k]: o[k] }))) }(lev)))} name="Level3Report">
                                        <ExcelColumn label="level 1 code" value="key" />
                                        <ExcelColumn label="level 1 name" value="name" />
                                        <ExcelColumn label="level 2 code" value="levelTwoKey" />
                                        <ExcelColumn label="level 2 name" value="levelTwoName" />
                                        <ExcelColumn label="level 3 code" value="allLvlKey" />
                                        <ExcelColumn label="level 3 name" value="levelThreeName" />
                                    </ExcelSheet>
                                </ExcelFile>

                                <button className="sendButton">
                                    <PDFDownloadLink style={{ textDecoration: "none",backgroundColor: "Black"}} document={<PDFLevel3 array={sortBy(props.level3List, ['props.level3List', 'levelThreeData.allLvlKey'])} />} fileName="Level3.pdf">
                                        {({ blob, url, loading, error }) =>
                                            loading ? 'Loading document...' : 'Download now!'
                                        }
                                    </PDFDownloadLink>
                                </button>
                            </div>
                        </Container>

                    )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLevel3Data: () => dispatch(getLevel3Data()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    level3List: state.accountsLevelReducer.level3List,
    isFetchinglevel3List: state.accountsLevelReducer.isFetchinglevel3List,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Level3Report);