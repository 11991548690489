import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import ImageUploader from "react-images-upload";
import { UploadTable, addTable, getHallById } from "./Table.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { setHeading } from "../../../accounts/account.actions";

const TableUpload = (props) => {
  const [restaurantId, setRestaurantId] = useState("");
  const [hallId, setHallId] = useState("");
  const [hallname, setHallName] = useState("");
  const [restaurantname, setRestaurantName] = useState("");
  const [tableFile, setTableFile] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const handleChangeRestaurant = (selectedOption) => {
    console.log(selectedOption, "sop");
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
    setHallName(null);
    setHallId(null);
    props.getHall(selectedOption.value);
  };
  const getRestaurantList =
    !isEmpty(props.restaurantDataList) &&
    props.restaurantDataList.map((x) => {
      let data = { value: x._id, label: x.restaurantName };
      return data;
    });
  const handleChangeHall = (selectedOption) => {
    console.log(selectedOption, "sop");
    setHallId(selectedOption.value);
    setHallName(selectedOption.label);
  };
  const getHallList =
    !isEmpty(props.hallListById) &&
    props.hallListById.map((x) => {
      let data = { value: x._id, label: x.hallName };
      return data;
    });
  function uploadTableFile() {
    console.log("fileeeeeeeeeee", tableFile);
    props.uploadTableFile(
      {
        file: tableFile,
      },
      restaurantId,
      hallId,
      handleVisible,
      handleDangerVisible
    );
  }

  useEffect(() => {
    props.getRestaurantData();
    props.setHeading("Table Upload");
  }, []);
  return (
    <>
      {
        //   props.hallDataList ||
        //    props.isFetchingRestaurant ||
        props.isUploadingTable ? (
          <div className="loader-div">
            <Button variant="info" disabled>
              <Spinner
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="info"
              />
              {props.isUploadingTable ? "Saving " : "Loading"}
            </Button>
          </div>
        ) : (
          <Container fluid>
            <div className="main">
              <Row>
                <Col lg="6" md="6" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select Restaurant:</b>
                    </Form.Label>
                    <Select
                      isDisabled={isEmpty(props.restaurantDataList)}
                      placeholder="Select Restaurant..."
                      onChange={handleChangeRestaurant}
                      options={getRestaurantList}
                    />
                  </Form.Group>
                </Col>
                <Col lg="6" md="6" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select Hall:</b>
                    </Form.Label>
                    <Select
                      isDisabled={isEmpty(props.hallListById)}
                      placeholder="Select Hall..."
                      onChange={handleChangeHall}
                      options={getHallList}
                      value={{ label: hallname }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div style={{ paddingTop: "2%" }}>
                <Row>
                  {/* <Col xl="4" lg="4" md="2" sm="2" xs="2"></Col> */}
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group>
                      <Form.Label>
                        <b style={{ fontSize: "large" }}>Upload Table File *</b>
                      </Form.Label>
                      {/* <ImageUploader
                        withIcon={false}
                        buttonText="Upload File"
                        onChange={(e) => setTabelFile(e.target.files[0])}
                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                       // imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={999999999999999999999}
                       // singleImage={true}
                        withPreview={true}
                      /> */}
                      <input
                        className="form-control"
                        type="file"
                        name="file"
                        class="Upload File"
                        id="upload file"
                        style={{ marginTop: "5%" }}
                        onChange={(e) => setTableFile(e.target.files[0])}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className="sendDiv">
                {restaurantId == "" || hallId == "" || tableFile == "" ? (
                  <Button
                    disabled
                    style={{
                      marginLeft: "2%",
                      color: "white",
                      width: "20%",
                      border: "1px solid",
                    }}
                  >
                    Save
                  </Button>
                ) : (
                  <Button onClick={uploadTableFile}>Save</Button>
                )}
              </div>
            </div>
          </Container>
        )
      }
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadTableFile: (
      data,
      restaurantId,
      hallId,
      handleVisible,
      handleDangerVisible
    ) =>
      dispatch(
        UploadTable(
          data,
          restaurantId,
          hallId,
          handleVisible,
          handleDangerVisible
        )
      ),
    getRestaurantData: () => dispatch(getRestaurantData()),
    setHeading: (data) => dispatch(setHeading(data)),
    getHall: (id) => dispatch(getHallById(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  tableList: state.TableReducer.tableList,
  isUploadingTable: state.TableReducer.isUploadingTable,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  hallDataList: state.hallReducer.hallDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
  hallListById: state.TableReducer.hallListById,
  isFetchingHallById: state.TableReducer.isFetchingHallById,
});

export default connect(mapStateToProps, mapDispatchToProps)(TableUpload);
