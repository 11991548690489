import { filter, isEmpty, toLower } from "lodash";
const initialState = {
     //restaurant
     restFilterData:[],
     restaurantList: [],
     restaurantDataList: [],
     isFetchingRestaurant: false,
     isAddingRestaurant: false,
     isEditingRestaurant:false,
};

const ResturantReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_RESTAURANT_GET_DATA":
    return { ...state, isFetchingRestaurant: true, };
case "SUCCESS_RESTAURANT_GET_DATA":
    return {
        ...state,
        restaurantDataList: action.payload.data,
        isFetchingRestaurant: false,
    };
case "ERROR_RESTAURANT_GET_DATA":
    return { ...state,};
    //add restaurant
    case "REQUEST_ADD_RESTAURANT":
      return { ...state, isAddingRestaurant: true};
    case "SUCCESS_ADD_RESTAURANT":
      return {
        ...state,
        restaurantList: action.payload,
        isAddingRestaurant: false,
      };
    case "ERROR_ADD_RESTAURANT":
      return { ...state, isAddingRestaurant: false};
      case "Edit_RESTAURANT":
       let restFilterData=filter(state.restaurantDataList,{'_id':action.payload})
        return {
          ...state,
          restFilterData:restFilterData
        };
        case "Edit_RESTAURANT_DATA":
          // let valueEmp = state.restFilterData;
          let valueEmp = state.restFilterData[0];
          console.log('state.BookingByIdList ', state.restFilterData)
          console.log('valueEmp', valueEmp)

          let keyEmp = action.payload.key;
          console.log('keyEmp', keyEmp)

          valueEmp[keyEmp] = action.payload.value;
          console.log('valueEmp', valueEmp)
          let valueEmp2 = [valueEmp];
          console.log('valueEmp2', valueEmp2)

          console.log('EDIT_BOOKING action.payload.data', action.payload)

          return { ...state, restFilterData: [valueEmp] };
      default:
        return state;
    }
  };
  export default ResturantReducer;