import { filter, isEmpty, toLower } from "lodash";

const initialState = {
    serviceAndHybridProductList: [],
    isFetchingServiceAndHybridProductList: false,
    //serivce product
    serviceProductList: [],
    productcom:[],
    isFetchingServiceProduct: false,
    productTypeListProduct: [],
    isFetchingProductTypeProduct: false,
    productTypeListById: [],
    isFetchingProductTypeById: false,
    variationListById: [],
    isFetchingvariationById: false,
    hideFormList:false,
    recipeList: [],
    isAddingRecipe: false,
    recipeEdit: [],
    isFetchingEditRecipe: false,
    stocks: [],
  isLoading: false,
  productSearchList:[],
  //get recipe
  isFetchingRecipe:false,
  recipeList:[],
  };
  
  const RecipeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_HIDE_FORM":
            return { ...state, 
                hideFormList: action.payload }; 
                //get service & hybrid product
case "REQUEST_SERVICE_AND_HYBRID_PRODUCT":
    return { ...state, isFetchingServiceAndHybridProductList: true};
case "SUCCESS_SERVICE_AND_HYBRID_PRODUCT":
    return {
        ...state,
        serviceAndHybridProductList: action.payload.data,
        productSearchList:action.payload.data,
        isFetchingServiceAndHybridProductList: false,
    };
case "ERROR_SERVICE_AND_HYBRID_PRODUCT":
    return { ...state,isFetchingServiceAndHybridProductList: false,
    };
    //filter product
    case "FILTER_PRODUCT":
        return{
            ...state,
            productSearchList:isEmpty(action.payload) ? state.serviceAndHybridProductList : filter(state.serviceAndHybridProductList, x => toLower(x.name).includes(toLower(action.payload)))
        }
        //filter by menu
        case "FILTER_ITEM_BY_MENU":
            console.log(state.serviceAndHybridProductList,"oooooooooooooo")
            return{
                ...state,   productSearchList:action.payload == 999999999999 ? state.serviceAndHybridProductList : filter(state.serviceAndHybridProductList, x =>!isEmpty(x.category)&& x.category._id == action.payload) ,
               
            }
            // RESET_RECIPE
            
            case "RESET_RECIPE":
                
                return{
                    ...state, recipeEdit:[],   
                   
                }
    //get serivce product
    case "REQUEST_SERVICE_PRODUCT":
    return { ...state, isFetchingServiceProduct: true};
case "SUCCESS_SERVICE_PRODUCT":
    return {
        ...state,
        serviceProductList: action.payload.data,
        isFetchingServiceProduct: false,
    };
case "ERROR_SERVICE_PRODUCT":
    return { ...state,};
    /////
    case "REQUEST_PRODUCT_TYPE_PRODUCT":
    return { ...state, isFetchingProductTypeProduct: true, };
case "SUCCESS_PRODUCT_TYPE_PRODUCT":
    return {
        ...state,
        productTypeListProduct: action.payload,
        productcom:action.payload.allData,
        isFetchingProductTypeProduct: false,
    };
case "ERROR_PRODUCT_TYPE_PRODUCT":
    return { ...state,};
    case "REQUEST_PRODUCT_TYPE_BYID":
    return { ...state, isFetchingProductTypeById: true, };
case "SUCCESS_PRODUCT_TYPE_BYID":
    return {
        ...state,
        productTypeListById: action.payload.data,
        isFetchingProductTypeById: false,
    };
case "ERROR_PRODUCT_TYPE_BYID":
    return { ...state,isFetchingProductTypeById:false};
    //variatio
    case "REQUEST_VARIATION_BYID":
    return { ...state, isFetchingvariationById: true, };
case "SUCCESS_VARIATION_BYID":
    return {
        ...state,
        variationListById: action.payload.data,
        recipeEdit: action.payload.data,
        isFetchingvariationById: false,
    };
case "ERROR_VARIATION_BYID":
    return { ...state,};
    //add recipe
    case "REQUEST_ADD_RECIPE":
        return { ...state, isAddingRecipe: true};
      case "SUCCESS_ADD_RECIPE":
        return {
          ...state,
          recipeList: action.payload,
          isAddingRecipe: false,
        };
      case "ERROR_ADD_RECIPE":
        return { ...state, isAddingRecipe: false};
        case "EDIT_ADDONS":
            let data= state.recipeEdit;
            data[0].recipeProducts=action.payload
            return {
                ...state,
                recipeEdit: data,
                isFetchingEditRecipe: false,
            };
          ///get recipe
          case "REQUEST_GET_RECIPE_DATA":
            return { ...state, isFetchingRecipe: true, };
        case "SUCCESS_GET_RECIPE_DATA":
            return {
                ...state,
                recipeList: action.payload.data,
                isFetchingRecipe: false,
            };
        case "ERROR_GET_RECIPE_DATA":
            return { ...state,isFetchingRecipe: false,};
            /////////////////
            case "REQUEST_RECIPEEDIT_BYID":
            return { ...state, isFetchingRecipeEdit: true, };
        case "SUCCESS_RECIPEEDIT_BYID":
            console.log(state.recipeEdit,"data11111111111")
            console.log(action.payload.item.recipeProducts,"data222222222")
            return {
                ...state,
                recipeEdit: state.recipeEdit,
                isFetchingRecipeEdit: false,
            };
        case "ERROR_RECIPEEDIT_BYID":
            return { ...state,isFetchingRecipeEdit: false};
            ////
            case "RESET_INT_STATE":
                return { ...state}; 
    default:
        return state;
    }
  };
  export default RecipeReducer;