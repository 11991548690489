import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Button, FormGroup, Alert, Tab, Tabs } from "react-bootstrap";
import { ModalBody, Modal } from "reactstrap";
// import {
//     getPurchase, getApproveDemand, addIssuance, getAllInventory, getIssuance, searchIssuanceReport
// } from "../AddDetails.actions";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
// import WareHouseModal from "../WareHouseModal";
// import MaterialTable from 'material-table';
// import AddIcon from "@material-ui/icons/Add";
// import RemoveIcon from "@material-ui/icons/Remove";
// import { IconButton } from "@material-ui/core";
import Select from 'react-select';
import Swal from 'sweetalert';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import moment from "moment";
import { addDayClosing, getDayOpening } from "./Day.action";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import IconMenu from "../../../drawer/IconMenu";
import { setHeading } from "../../../accounts/account.actions";
const ClosingDay = (props) => {

    const [cash, setCash] = useState('');
    const [opening, setOpening] = useState(
        {
            id: '',
            label: '',
            openingCash:0,
            sumCancelAmount:0,
            sumDiscountAmount:0,
            sumOrderAmount:0,
            totalCash:0,

        }
    );
    console.log(props.dayOpeningList,"hmhmhm")
    // const openingList = !isEmpty(props.dayOpeningList) ? props.dayOpeningList.data.map((x) => { return { value: x._id, label: `${x.dayOpenDate} ${x.dayOpenName}`,openingCash: x.cashInHand,sumCancelAmount:x.sumCancelAmount,sumDiscountAmount:x.sumDiscountAmount,sumOrderAmount:x.sumOrderAmount,totalCash:x.totalCash }; }) : [];
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [userClick, setUserClick] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleVisible = (msg) => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    }
    const handleChangeOpening=(e)=>{
        setOpening({
            id: e.value,
            label: e.label,
            openingCash:e.openingCash,
            sumCancelAmount:e.sumCancelAmount,
            sumDiscountAmount:e.sumDiscountAmount,
            sumOrderAmount:e.sumOrderAmount,
            totalCash:e.totalCash,
        })
    }
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      function Logout() {
        window.localStorage.clear();
        window.location.replace("/login");
      }
      function uploadPurchase() {
        props.addDayClosing({cash: cash, dayOpenId: props.dayOpeningList.data._id}, handleVisible, handleDangerVisible)
    setCash('');
    setOpening({
        id: '',
        label: '',
        openingCash:0,
        sumCancelAmount:0,
            sumDiscountAmount:0,
            sumOrderAmount:0,
            totalCash:0,

    })
    }
    useEffect(() => {
        props.getDayOpening();
        props.setHeading("Day Close")
    }, []);

    return (
        <>
        
            {props.isGettingDayOpening ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isAddingIssuance ? 'Saving...' : 'Loading...'}

                    </Button>
                </div>
            ) : 
         
            (
                
                <Container fluid>
                    <Form>
                        <Row>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Date *</b>
                                    </Form.Label>
                                    <Form.Control type="date" placeholder="Date...." name="Date" value={moment(new Date()).format("YYYY-MM-DD")} disabled={true} />
                                    {/* <Select
                                        placeholder="Select Requistion Type..."
                                        onChange={(e) => handleChangeRequistion(e)}
                                        options={getRequistion}
                                    /> */}
                                </Form.Group>
                            </Col>

                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Opening *</b>
                                    </Form.Label>
                                     {/* <Select
                                        placeholder="Select Opening..."
                                        onChange={(e) => handleChangeOpening(e)}
                                        options={openingList}
                                    /> */}
                                    <Form.Control type="text" placeholder="Cash...." name="Cash" value={!isEmpty(props.dayOpeningList)?props.dayOpeningList.data.dayOpenDate+props.dayOpeningList.data.dayOpenName:""} disabled  />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                        <Col xl="3" lg="3" md="3" sm="3">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Opening Cash *</b>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Opening Cash...." disabled value={!isEmpty(props.dayOpeningList)?props.dayOpeningList.data.cashInHand:0}  />
                                </Form.Group>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Cancel Order Cash *</b>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Cancel Order Cash...." disabled value={!isEmpty(props.dayOpeningList)?props.dayOpeningList.sumCancelAmount:0}  />
                                </Form.Group>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Dicounted order Cash *</b>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Dicounted order Cash...." disabled value={!isEmpty(props.dayOpeningList)?props.dayOpeningList.sumDiscountAmount:0}  />
                                </Form.Group>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Order Cash *</b>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Order Cash...." disabled value={!isEmpty(props.dayOpeningList)?props.dayOpeningList.sumOrderAmount:0}  />
                                </Form.Group>
                            </Col>  
                        </Row>
                     
                        <Row>
                        <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Total Cash *</b>
                                    </Form.Label>
                                    <Form.Control type="text" placeholder="Total Cash...." disabled value={!isEmpty(props.dayOpeningList)?props.dayOpeningList.totalCash:0}  />
                                </Form.Group>
                            </Col>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Cash *</b>
                                    </Form.Label>
                                    <Form.Control type="number" placeholder="Cash...." name="Cash" value={cash} disabled={isEmpty(props.dayOpeningList)?true:false} onChange={(e) => setCash(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="sendDiv">
                            <Button  disabled={cash==""} style={{marginTop: '15px'}} onClick={uploadPurchase }>
                                Day Close</Button>
                        </div>
                    </Form>
                </Container>
            )
            }
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      

        getDayOpening: () => dispatch(getDayOpening()),
        addDayClosing: (data, handleVisible, handleDangerVisible) => dispatch(addDayClosing(data, handleVisible, handleDangerVisible)),
        setHeading:(data)=>dispatch(setHeading(data))

    };
};
const mapStateToProps = (state, ownProps) => ({

    isGettingDayOpening: state.DayReducer.isGettingDayOpening,
    dayOpeningList: state.DayReducer.dayOpeningList,

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClosingDay);