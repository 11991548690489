const initialState = {
  issuance: [],
  isAddingIssuance: false,
  isFetchingIssuance: false,
  IssuanceList: [],
};

const IssuanceReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_ADD_ISSUANCE":
      return { ...state, isAddingIssuance: true };
    case "SUCCESS_ADD_ISSUANCE":
      return { ...state, issuance: action.payload, isAddingIssuance: false };
    case "ERROR_ADD_ISSUANCE":
      return { ...state, isAddingIssuance: false };

    case "REQUEST_GET_ISSUANCE":
      return { ...state, isFetchingIssuance: true };
    case "SUCCESS_GET_ISSUANCE":
      return {
        ...state,
        IssuanceList: action.payload.data,
        isFetchingIssuance: false,
      };
    case "ERROR_GET_ISSUANCE":
      return { ...state, isFetchingIssuance: false };
    default:
      return state;
  }
};
export default IssuanceReducer;
