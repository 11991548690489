import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import MaterialTable from 'material-table';
import moment from 'moment'
import UserModal from './UserModal';
import {Navigate} from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {getVendorsDetails, postVendorsDetails, deleteVendors, updateVendors} from './users.actions';
import {isEmpty} from 'lodash'
 
const Users = (props)  => {
  const vendorsData= props.vendorsList;  
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    columns: [
      { title: 'First Name', field: 'firstName' },
      { title: 'Last Name', field: 'lastName' },
      { title: 'Vendor Name', field: 'username' },
      { title: 'Email', field: 'email' },
      { title: 'Phone No', field: 'phone', type: 'numeric', },
      { title: 'Business Address', field: 'businessName' },
      { title: 'Company Name', field: 'companyName' },
    ],

    // data: [
    //     { firstName: 'Zahra', lastName: 'Nabeel', email: 'zahra@gmail.com', phone: '028393263772', city: 'sialkot' }
    //   ]
  });

  useEffect(() => {
    // props.getUsers();
    props.getVendorsDetails();
  }, []);

  const [callEdit, setcallEdit] = React.useState(false);
  if (callEdit) {
    return <Navigate push to="/admin/view-user" />;
  }
  
  return (
    <>    
    { open && <UserModal open = {open}/>}
    <MaterialTable
      title="Vendors"
      columns={state.columns}
       data={vendorsData}
    //data={state.data}
      actions={[
        // {
        // icon: VisibilityIcon,
        //   tooltip: 'View User',
        //   onClick: (event, rowData) => {
        //     // Do save operation
        //     setcallEdit(true);
            
        //    props.selectedUser(rowData)
        //   }
        // }
      ]}
      options={{
        actionsColumnIndex: -1,
        paging:true,
        pageSize:30,       // make initial page size
        emptyRowsWhenPaging: false,   // To avoid of having empty rows
        pageSizeOptions:[50,100,150,200], 
        headerStyle: { position: 'sticky', top: 0,
        color: '#00BBBB',
        fontWeight: '550',
        onRowAdd: 'none',
        }
      }}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
             props.postVendorsDetails(newData)
            }, 600);
          }),

        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              if (oldData) {
                props.updateVendors(newData, oldData)

              }
            }, 600);
          }),

        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              props.deleteVendors(oldData.vendorId);
            }, 600);
          }),
      }}
    />
    </>
  );
}

const mapDispatchToProps = dispatch => {
return{
  // postUsersDetails: (data) => dispatch(postUsersDetails(Object.assign(data, {admin_id: "123"}))),
  // getUsers: () => dispatch(getUsers()),
  // updateUser: (data, oldData) => dispatch(updateUser(data, oldData)),
  // deleteUser: (user_id) => dispatch(deleteUser(user_id)),
  // selectedUser: (data) => dispatch(selectedUser(data)),
  getVendorsDetails : () => dispatch(getVendorsDetails()),
  postVendorsDetails : (data) => dispatch(postVendorsDetails(data)),
  deleteVendors : (vendorId) => dispatch(deleteVendors(vendorId)),
  updateVendors : (newData, oldData) => dispatch(updateVendors(newData, oldData))
}
}

const mapStateToProps = (state, ownProps) => ({
 vendorsList: state.usersReducer.vendorsList

});

export default connect(mapStateToProps,mapDispatchToProps)(Users);