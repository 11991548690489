import API from "../../../../../global/api";
export const requestRestaurantData = () => {
    return {
        type: "REQUEST_RESTAURANT_GET_DATA",
    };
};
export const successRestaurantGetData = (data) => {
    return {
        type: "SUCCESS_RESTAURANT_GET_DATA",
        payload: data,
    };
};
export const errorRestaurantGetData = () => {
    return {
        type: "ERROR_RESTAURANT_GET_DATA",
    };
};
export const requestAddRestaurant = () => {
    return {
        type: "REQUEST_ADD_RESTAURANT",
    };
};
export const successAddRestaurant = (data) => {
    return {
        type: "SUCCESS_ADD_RESTAURANT",
        payload: data,
    };
};
export const errorAddRestaurant = () => {
    return {
        type: "ERROR_ADD_RESTAURANT",
    };
};
export const getRestaurantData = () => {
    return (dispatch) => {
        dispatch(requestRestaurantData());
        API.get(`/restaurant?page=0&limit=1000`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successRestaurantGetData(getData));
        }).catch((error) => {
            dispatch(errorRestaurantGetData());
        })
    };
};
export const addRestaurant = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddRestaurant());
        API.post(`/restaurant`, data).then((res) => {
          //  dispatch(getRestaurantData());
            dispatch(successAddRestaurant(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddRestaurant());
            handleDangerVisible(error.response.data.message);
        })
    };
};
export const DeleteRequestRestaurant = () => {
    return {
        type: "DELETE_REQUEST_RESTAURANT",
    };
};
export const DeleteSuccessRestaurant = (user_id) => {
    return {
        type: "DELETE_SUCCESS_RESTAURANT",
        user_id: user_id,
    };
};
export const DeleteErrorRestaurant = () => {
    return {
        type: "DELETE_ERROR_RESTAURANT",
    };
};
export const deleteRestaurant = (vendorId) => {
    return dispatch => {
        dispatch(DeleteRequestRestaurant());
        API.delete(`/restaurant/${vendorId}`)
            .then(res => {
               // dispatch(getRestaurantData());
                dispatch(DeleteSuccessRestaurant(vendorId))
            }).catch((error) =>
            {
                dispatch(DeleteErrorRestaurant())
    });
    }
};
export const UpdateRequestRestaurant = () => {
    return {
        type: "UPDATE_REQUEST_RESTAURANT",
    };
};
export const UpdateSuccessRestaurant = (data) => {
    return {
        type: "UPDATE_SUCCESS_RESTAURANT",
        payload: data,
    };
};

export const UpdateErrorRestaurant = () => {
    return {
        type: "UPDATE_ERROR_RESTAURANT",
    };
};

export const updateRestaurant = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestRestaurant());
        console.log(data,'hmhm')
        API.put(`/restaurant/${oldData._id}`,
            {
                restaurantName: data.restaurantName,
                restaurantLocation:data.restaurantLocation,
                restaurantDesc:data.restaurantDesc,
                // tax:data.tax,
                kds:data.kds=="1"?true:false,
                ntn:data.ntn,
                taxType:data.taxType,
                praTaxCash:data.praTaxCash,
                praTaxCard:data.praTaxCard,
                fbrTaxCash:data.fbrTaxCash,
                fbrTaxCard:data.fbrTaxCard,
            },{
        })
            .then(res => {
               // dispatch(getRestaurantData());
                dispatch(UpdateSuccessRestaurant([data]))
            }).catch((error) =>
                dispatch(UpdateErrorRestaurant()));
    }
};
export const EditRestaurant = (id) => {
    return {
        type: "Edit_RESTAURANT",
        payload: id,
    };
};
export const EditRestData = (data) => {
    return {
        type: "Edit_RESTAURANT_DATA",
        payload: data,
        
    };
};