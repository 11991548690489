import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
} from "react-bootstrap";

import { ModalBody, Modal } from "reactstrap";

import { connect } from "react-redux";
import { isEmpty, find, isUndefined } from "lodash";
import Spinner from "react-bootstrap/Spinner";

import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import Select from "react-select";
import Swal from "sweetalert";
import { searchIssuanceReportByDate } from "./Issuance.action";

const IssuanceReportByDate = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      productQuantityCount: "",
      productQuantity: 0,
    },
  ]);

  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [department, setdepartment] = useState("");
  const [productionOrder, setProductionOrder] = useState();
  const [supervisor, setSupervisor] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [departmentLabel, setDepartmentLabel] = useState();
  const [purchaseNameValue, setPurchaseNameValue] = useState();
  const [purchaseNameLabel, setPurchaseNameLabel] = useState();
  const [issuanceNameValue, setIssuanceNameValue] = useState();
  const [issuanceNameLabel, setIssuanceNameLabel] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const getSectionDepartmentType = [
    { value: '64f08ad147352a7e3cd3356b', label: 'Pakistani' },
    { value: '64f08b1747352a7e3cd3356e', label: 'Turkish' },
    { value: '64f08b4847352a7e3cd33571', label: 'Chinese' },
    { value: '64f08b6747352a7e3cd33574', label: 'B.B.Q' },
    { value: '64f08b7547352a7e3cd33577', label: 'B.A.R' },
    { value: '64f08b9e47352a7e3cd3358a', label: 'Bakery' },
    { value: '64f08bb047352a7e3cd3358d', label: 'Continental' },
    { value: '64f08bb047352a7e3cd3358b', label: 'Tandoor' },
    { value: '64f08bb047352a7e3cd3358c', label: 'Cold Kitchen' },
    { value: '64f08bb047352a7e3cd3358e', label: 'Marquee Events' },
    { value: '64f08bb047352a7e3cd3358f', label: 'Marquee Office' },
    { value: '64f08bb047352a7e3cd33587', label: 'Staff' },
    { value: '64f08bb047352a7e3cd33583', label: 'Nouman Sb' },
    { value: '64f08bb047352a7e3cd33584', label: 'MD Office' },
    { value: '64f08bb047352a7e3cd33581', label: 'Complimentary' },
    { value: '64f08bb047352a7e3cd33582', label: 'Front' },
  ];
  const handleChangeProductType = (selectedOption) => {
    setdepartment(selectedOption.value);
    setDepartmentLabel(selectedOption.label);
  };
  const handleVisible = () => {
    setAlertVisible(true)
    setTimeout(() => {
        setAlertVisible(false)
    }, 9000);
    Swal({
        title: "Data Not Found",
        text: "Data Not Found",
        icon: "info",
        button: "Ok",
    });
}

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

//   const handleChangeGetIssuance = (selectedOption) => {
//     setIssuanceNameValue(selectedOption.value);
//     console.log(issuanceNameValue, "oooooooooooppppppppppppppp");
//     setIssuanceNameLabel(selectedOption.label);
//   };

//   const getIssuanceList = props.IssuanceList.map((x) => {
//     let data = { value: x.issuanceId, label: x.issuanceId };
//     return data;
//   });

  const exportPDF = () => {
    props.searchIssuanceReport(
      {
         startDate : !isEmpty(startDate) ? startDate : "",
          endDate: !isEmpty(endDate) ? endDate : "",
          department:department,
      //  issuanceId: issuanceNameValue,
      },
      startDate,
      endDate,
      departmentLabel,
      handleVisible
    );
  };

  useEffect(() => {
   // props.getIssuance();
  }, []);
  return (
    <>
      {props.isFetchingIssuance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>Start Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <FormGroup>
                  <Form.Label>
                    <b>End Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Section/Department </b>
                  </Form.Label>
                  <Select
                    value={{ label: departmentLabel }}
                    placeholder="Select Product Type..."
                    onChange={(e) => handleChangeProductType(e)}
                    options={getSectionDepartmentType}
                  />

                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv" style={{marginTop:'15px'}}>
              {isUndefined(startDate) || isUndefined(endDate) ? (
                <Button
                  disabled
                  style={{
                    marginLeft: "2%",
                    color: "white",
                    width: "20%",
                    backgroundColor: "#1976d2",
                    border: "1px solid #1976d2",
                  }}
                >
                  Generate Issuance Report
                </Button>
              ) : props.isFetchingSearchIssuanceList ? (
                <Button >Downloading PDF</Button>
              ) : (
                <Button  onClick={() => exportPDF()}>
                  Generate Issuance Report
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchIssuanceReport: (data, startDate, endDate,departmentLabel,handleVisible) =>
      dispatch(
        searchIssuanceReportByDate(data, startDate, endDate,departmentLabel,handleVisible)
      ),
  };
};
const mapStateToProps = (state, ownProps) => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssuanceReportByDate);
