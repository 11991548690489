import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
} from "react-bootstrap";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { deleteSize, getSizeData, updateSize } from "./Size.action";
import { baseURL ,headers} from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
const ViewSize = (props) => {
  const permissions=JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType,permissionsName) => {
    console.log(permissionsType,permissionsName,"typeeeeeeeeeeeeeeeeeeeeeeeee")
   return permissions.some(p => p[`${permissionsType}`] === permissionsName)?false:true
  }
  useEffect(() => {
    props.setHeading("Size List");
   // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingCouponsData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
        <div className="main">
        <MaterialSize
                  title="Size Details"
                  columns={[
                    // { title: 'Size Id', field: 'table_id', editable: 'never' },
                    { title: "Size Name", field: "sizeName" },
                    { title: "Size Description", field: "sizeDesc" },
                  ]}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      let url = `${baseURL}/size?`;
                      url += "limit=" + query.pageSize;
                      url += "&page=" + (query.page );
                      url += "&search=" + query.search;
                      fetch(url, {
                        method: "GET",
                        headers: headers,
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          resolve({
                            data: result.data,
                            page: result.page,
                            totalCount: result.total_results,
                          });
                        });
                    })
                  }
                  options={{
                    actionsColumnIndex: -1,
                    // filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    paging: true,
                    pageSize: 50, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [30, 50, 100,150,200], // rows selection options
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    isEditHidden: rowData => Checkpermissions("permissionsSubCatName","Size Edit"),
                   isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName","Size Delete"),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          {
                            console.log(newData,oldData,"dataaaaaaaaaaaaaaa")
                            props.updateSize(newData, oldData);
                          }
                        }, 600);
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteSize(oldData._id);
                        }, 600);
                      }),
                  }}
                />
                </div>
                </Container>
                )}
                </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getSizeData: () => dispatch(getSizeData()),
    deleteSize: (tableId) => dispatch(deleteSize(tableId)),
    updateSize: (newData, oldData) => dispatch(updateSize(newData, oldData)),
    setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
    sizeDataList: state.sizeReducer.sizeDataList,
  isFetchingSize: state.sizeReducer.isFetchingSize,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewSize);
