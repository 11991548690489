import API from "../../../../../global/api";
import jsPDF from "jspdf";
import logo from "../../../../../../src/Images/logo.png";
import moment from "moment";
import { isEmpty, isNull, sortBy, sumBy } from "lodash";
import { handleNoDataVisible } from "../../../../../global/globalFunctions";
export const requestProductData = () => {
    return {
        type: "REQUEST_PRODUCT_GET_DATA",
    };
};
export const successProductGetData = (data) => {
    return {
        type: "SUCCESS_PRODUCT_GET_DATA",
        payload: data,
    };
};
export const errorProductGetData = () => {
    return {
        type: "ERROR_PRODUCT_GET_DATA",
    };
};
//get product balance
export const requestProductBalance = () => {
    return {
        type: "REQUEST_PRODUCT_GET_BALANCE",
    };
};
export const successProductGetBalance = (data) => {
    return {
        type: "SUCCESS_PRODUCT_GET_BALANCE",
        payload: data,
    };
};
export const errorProductGetBalance = () => {
    return {
        type: "ERROR_PRODUCT_GET_BALANCE",
    };
};
export const getProductBalance = () => {
    return (dispatch) => {
        dispatch(requestProductBalance());
        API.get(`/products/update_ledgerBlnc_to_productBlnc`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successProductGetBalance(getData));
        }).catch((error) => {
            dispatch(errorProductGetBalance());
        })
    };
};

//Add Vendor Data
export const requestAddProduct = () => {
    return {
        type: "REQUEST_ADD_PRODUCT",
    };
};
export const successAddProduct = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCT",
        payload: data,
    };
};
export const errorAddProduct = () => {
    return {
        type: "ERROR_ADD_PRODUCT",
    };
};

export const getProductData = () => {
    return (dispatch) => {
        dispatch(requestProductData());
        API.get(`/products`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successProductGetData(getData));
        }).catch((error) => {
            dispatch(errorProductGetData());
        })
    };
};
export const requestGetSimpleCompleteProduct = () => {
    return {
        type: "REQUEST_GET_SIMPLE_COMPLETE_PRODUCT",
    };
};
export const successGetSimpleCompleteProduct = (data) => {
    return {
        type: "SUCCESS_GET_SIMPLE_COMPLETE_PRODUCT",
        payload: data,
    };
};
export const errorGetSimpleCompleteProduct = () => {
    return {
        type: "ERROR_GET_SIMPLE_COMPLETE_PRODUCT",
    };
};
export const getSimpleCompleteProduct = () => {
    return (dispatch) => {
        dispatch(requestGetSimpleCompleteProduct());
        API.get(`products/complete_product_simple`)
        .then((res) => {
            let getData = res.data;
            console.log(getData, "kkkkkkk");
            if(isEmpty(getData)){
              handleNoDataVisible()
            }
            else{
            const unit = "pt";
            const size = "A4"; // Use A1, A2, A3 or A4
            const orientation = "potrait"; // portrait or landscape
    
            const marginLeft = 40;
            const doc = new jsPDF(orientation, unit, size);
            const title = "Store Product List";
            const companyName = `${getData.resImage.restaurantName}`;
            const companyAddress = `${getData.resImage.restaurantLocation}`;
            const companyNtn = `${getData.resImage.ntn}`;
            const logo1 = getData.restaurantImage;
            // const Date1 = getData.orderDate;
            // // const Date2 = getData.endDate;
            // // const restaurant = getData.data[0].restaurant.restaurantName;
            // const DisName = isNull(getData.reprintOrderResult.coupon)
            //   ? "Null"
            //   : getData.reprintOrderResult.coupon.name;
            // const DiscountAmount = getData.reprintOrderResult.discount;
            // //  const closecash = getData.totalClosingCash;
            // const totalOrderAmount = getData.reprintOrderResult.grandTotal;
            // const Customer = getData.reprintOrderResult.customer.name;
            const headers = [
              [
                "Code",
                "Product Name",
                "UOM",
                "Product Balance",
                "Product Purchase Price",
                "Amount",
              ],
            ];
            const data = getData.completeProductsResults.map((elt, index) => [
               elt.code,
              elt.name,
              elt.uomLabel,
              elt.productBalance,
              elt.purchasePrice,
              elt.productBalance*elt.purchasePrice,
            ]);
            const total =sumBy(data,"5").toFixed(1)
            let content = {
              startY: 140,
              startX: 10,
              head: headers,
              body: data,
            };
            doc.addImage(logo1, "PNG", 40, 15, 100, 100);
            doc.setFontSize(20);
            doc.setFont(undefined, "bold");
            doc.text(companyName, 150, 50, 0, 20);
            doc.setFontSize(13);
            doc.setFont("TimesNewRoman", "Normal");
            doc.text(companyAddress, 150, 70, 0, 20);
            doc.text(companyNtn, 150, 90, 0, 20);
            // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
            //doc.text(`Status: ${status}`, 350, 150, 0, 20);
            // doc.text(`Customer Name: ${Customer}`, marginLeft, 170, 0, 20);
            // doc.text(`Coupon: ${DisName}`, 350, 170, 0, 20);
            // doc.text(`Discount Amount: ${DiscountAmount}`, marginLeft, 190, 0, 20);
            // doc.text(`Total Order Amount: ${totalOrderAmount}`, 350, 190, 0, 20);
            // // doc.text(`Total Order Length: ${todaysOrderLength}`, 350, 210, 0, 20);
            doc.setFontSize(18);
            doc.setFont("TimesNewRoman", "bold");
            doc.text(title, 215, 120);
            doc.setFontSize(13);
            doc.setFont("TimesNewRoman", "Normal");
            // doc.text(`Date :${moment(Date1).format("YYYY-MM-DD")}`, 210, 140);
            doc.setFontSize(11);
            doc.autoTable(content);
            doc.text(`Total : ${total}`,440,doc.lastAutoTable.finalY+20)
            doc.setProperties({
              title: `Store Product List ${moment(new Date()).format("YYYY-MM-DD")}.pdf`,
            });
            //   doc.output('dataurlnewwindow')
            doc.save(
              `Store Product List ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
            );}
            dispatch(successGetSimpleCompleteProduct(getData));
          })
          .catch((error) => {console.log(error,"testerror")
            dispatch(errorGetSimpleCompleteProduct());
        })
    };
};

//Adding single vender

export const addProduct = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddProduct());
        API.post(`/products`, data).then((res) => {
            dispatch(getProductData());
            dispatch(successAddProduct(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddProduct());
            handleDangerVisible(error.response.data.message);
        })
    };
};

//Api call for deleting Vendor

export const DeleteRequestProduct = () => {
    return {
        type: "DELETE_REQUEST_PRODUCT",
    };
};
export const DeleteSuccessProduct = (user_id) => {
    return {
        type: "DELETE_SUCCESS_PRODUCT",
        user_id: user_id,
    };
};
export const DeleteErrorProduct = () => {
    return {
        type: "DELETE_ERROR_PRODUCT",
    };
};
export const deleteProduct = (vendorId) => {
    return dispatch => {
        dispatch(DeleteRequestProduct());
        API.delete(`/products/${vendorId}`)
            .then(res => {
                dispatch(getProductData());
                dispatch(DeleteSuccessProduct(vendorId))
            }).catch((error) =>
            {
                dispatch(DeleteErrorProduct())
    });
    }
};
export const UpdateRequestProduct = () => {
    return {
        type: "UPDATE_REQUEST_PRODUCT",
    };
};
export const UpdateSuccessProduct = (data) => {
    return {
        type: "UPDATE_SUCCESS_PRODUCT",
        payload: data,
    };
};

export const UpdateErrorProduct = () => {
    return {
        type: "UPDATE_ERROR_PRODUCT",
    };
};

export const updateProduct = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestProduct());
        console.log(data,'hmhm')
        API.put(`/products/${oldData._id}`,
            {
                name: data.name, 
                discount:data.discount,
                desc:data.desc,
                greenColorTime:data.greenColorTime,
                yellowColorTime:data.yellowColorTime,
                redColorTime:data.redColorTime,
                productBalance:data.productBalance,
                purchasePrice:data.purchasePrice,
                salePrice:data.salePrice,
                //category:data.category,    
            },{
        })
            .then(res => {
                dispatch(getProductData());
                dispatch(UpdateSuccessProduct([data]))
            }).catch((error) =>
                dispatch(UpdateErrorProduct()));
    }
};

export const requestAddProductImage = () => {
    return {
        type: "REQUEST_ADD_PRODUCT_IMAGE",
    };
};
export const successAddProductImage = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCT_IMAGE",
        payload: data,
    };
};
export const errorAddProductImage = () => {
    return {
        type: "ERROR_ADD_PRODUCT_IMAGE",
    };
};
export const addProductImage = (data) => {
    return (dispatch) => {
        console.log(data,"dddddddddddddddddddddd")
        const formData = new FormData();
       // formData.append("image", get(data, 'image'));
        formData.append("image",data.image)
        // .then(x => {
        //     console.log(formData, "dt")
        // })
        console.log(formData,"iiiiiiiiiiiii")
        console.log(formData,"iiiiiiiiiiiii11")
            dispatch(requestAddProductImage());
            API.post(`/files/image`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                let getData = res.data;
                dispatch(successAddProductImage(getData));
            }).catch((error) => {
                dispatch(errorAddProductImage());
            })
        }};
        export const resetReducer = () => {
            return {
                type: "RESET_PRODUCT_URL",
            };
        };
    //     console.log(data,"check")
    //     let data1 = new FormData();
    //     data1.append('image', data);
    //     fetch('/files/image', {
    //       method: 'POST',
    //       body: data1
    //     }).then(response => {
    //         dispatch(successAddProductImage(data1));
    //     }).catch(err => {
    //         dispatch(errorAddProductImage());
    //     });
    //   }}
    //     // dispatch(requestAddProductImage());
    //     // API.post(`/files/image`, data).then((res) => {
    //     //     dispatch(successAddProductImage(data));
    //     // }).catch((error) => {
    //     //     dispatch(errorAddProductImage());
    //     // })
    export const requestUOM = () => {
        return {
            type: "REQUEST_UOM",
        };
    };
    export const successUOM = (data) => {
        return {
            type: "SUCCESS_UOM",
            payload: data,
        };
    };
    export const errorUOM = () => {
        return {
            type: "ERROR_UOM",
        };
    };
    export const getUOM = () => {
        return (dispatch) => {
            dispatch(requestUOM());
            API.get(`/uom?limit=1000&page=0&search`).then((res) => {
                let getData = res.data;
                console.log(getData,'List')
                dispatch(successUOM(getData));
            }).catch((error) => {
                dispatch(errorUOM());
            })
        };
    };
    export const requestProductType = () => {
        return {
            type: "REQUEST_PRODUCT_TYPE",
        };
    };
    export const successProductType = (data) => {
        return {
            type: "SUCCESS_PRODUCT_TYPE",
            payload: data,
        };
    };
    export const errorProductType = () => {
        return {
            type: "ERROR_PRODUCT_TYPE",
        };
    };
    export const getProductType = () => {
        return (dispatch) => {
            dispatch(requestProductType);
            API.get(`/products/productTypes`).then((res) => {
                let getData = res.data;
                console.log(getData,'List')
                dispatch(successProductType(getData));
            }).catch((error) => {
                dispatch(errorProductType());
            })
        };
    };