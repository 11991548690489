import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
} from "react-bootstrap";
import { Link } from "react-router-dom";
// import "./add-details.css";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL ,headers} from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import { isEmpty } from "lodash";
const ViewUom = (props) => {
  const permissions=JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType,permissionsName) => {
    console.log(permissionsType,permissionsName,"typeeeeeeeeeeeeeeeeeeeeeeeee")
   return permissions.some(p => p[`${permissionsType}`] === permissionsName)?false:true
  }
  useEffect(() => {
    props.setHeading("Uom List");
   // console.log(props.couponsDataList, "list");
  }, []);
  return (
    <>
      {props.isFetchingCouponsData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
        <div className="main">
        <MaterialTable
                  title="UOM Details"
                  columns={[
                    { title: "uom Id", field: "_id" },
                    { title: "Uom Name", field: "unitName" ,
                },
                { title: "Uom Code", field: "unitCode" ,
              },
                  
                  ]}
                 // data={wareHouseData}
                     data={(query) =>
                      new Promise((resolve, reject) => {
                          let url = `${baseURL}/uom?`;
                          url += "limit=" + query.pageSize;
                          url += "&page=" + (query.page);
                           url += "&search=" + query.search;
                          fetch(url, {
                              method: 'GET',
                              headers: headers,
                          })
                              .then((response) => response.json())
                              .then((result) => {
                                  resolve({
                                      data: result.data,
                                      page: result.page,
                                      totalCount: result.total_results,
                                  });
                              });

                      })
                  }
                  options={{
                    actionsColumnIndex: -1,
                    exportButton: true,
                    exportAllData: true,
                    paging: true,
                    pageSize: 50, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [20,50, 100,150, 200], // rows selection options
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },}}
                />
                </div>
                </Container>
                )}
                </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
      setHeading:(data)=>dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
  flavorsDataList: state.FlavourReducer.flavorsDataList,
  sizeDataList: state.sizeReducer.sizeDataList,
  masterControlDataList:state.MasterControlReducer.masterControlDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUom);
