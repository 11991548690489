import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Alert,
  Tab,
  Tabs,
  Breadcrumb,
} from "react-bootstrap";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, isNull } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import jsPDF from "jspdf";
//import logo from "../../../../../../Images/logo.png";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import nointernet from "../../../../Images/nointernetgif.gif"
import Grid from "@material-ui/core/Grid";
const NoInternet = (props) => {
 
  return (
    <>
        <Container fluid>
              {/* <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
             Day Wise Discount Report 
            </Typography>
            <div>
              <IconButton
                id="basic-button"
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <SettingsIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <Link to='/day/opening'>
                  <MenuItem onClick={handleClose}>Opening Day</MenuItem>
                </Link>
                <Link to='/day/closing'>
                <MenuItem onClick={handleClose}>Closing Day</MenuItem>
                </Link>
                 <MenuItem onClick={handleClose}>Logout</MenuItem>
               </Menu>
            </div>
            <Button color="inherit">Logout</Button>
          </Toolbar>
        </AppBar>
      </Box> */}
         <Grid>
          <img src={nointernet} alt="loading..." style={{display: "block",marginLeft: "auto", marginRight: "auto"}}/>
           <Row>
           <Col lg="5" md="5" xs="5"></Col>
           <Col lg="2" md="2" xs="2"><Button className="btn btn-primary" onClick={()=>{window.location.reload()}}>Reload</Button> </Col> 
          <Col lg="5" md="5" xs="5"></Col>
          </Row>
        </Grid>

        </Container>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {

  };
};
const mapStateToProps = (state, ownProps) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(NoInternet);
