import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  customerList: [],
  customerDataList: [],
  isFetchingCustomer: false,
  isAddingCustomer: false,
};

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_CUSTOMER_GET_DATA":
      return { ...state, isFetchingCustomer: true };
    case "SUCCESS_CUSTOMER_GET_DATA":
      return {
        ...state,
        customerDataList: action.payload.data,
        isFetchingCustomer: false,
      };
    case "ERROR_CUSTOMER_GET_DATA":
      return { ...state };
    //add customer
    case "REQUEST_ADD_CUSTOMER":
      return { ...state, isAddingCustomer: true };
    case "SUCCESS_ADD_CUSTOMER":
      return {
        ...state,
        customerList: action.payload,
        isAddingCustomer: false,
      };
    case "ERROR_ADD_CUSTOMER":
      return { ...state, isAddingCustomer: false };
    default:
      return state;
  }
};
export default CustomerReducer;
