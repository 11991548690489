const initialState = {
  isFetchingGetAllVoucher: false,
  getAllVoucher: [],
  addVoucher: [],
  isFetchingGetAddVoucher: false,
  allVoucher: [],
  isFetchingAllVoucher: false,
  cashPayment: [],
  cashRecipet: [],
  bankPayment: [],
  bankRecipet: [],
  journal: [],
  val: [],
  voucherList: [],
  isFetchingVoucherList: false,
  searchVoucherList: [],
  isFetchingSearchVoucherList: false,
  isFetchingadminVoucherList: false,
  voucherListUser: [],
};

const VoucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_GET_ALL_VOUCHER_TYPE":
      return { ...state, isFetchingGetAllVoucher: true };
    case "SUCCESS_GET_ALL_VOUCHER_TYPE":
      return {
        ...state,
        getAllVoucher: action.payload.data,
        isFetchingGetAllVoucher: false,
      };
    case "ERROR_GET_ALL_VOUCHER_TYPE":
      return { ...state, isFetchingGetAllVoucher: false };

    case "REQUEST_ADD_VOUCHER":
      return { ...state, isFetchingGetAddVoucher: true };
    case "SUCCESS_ADD_VOUCHER":
      return {
        ...state,
        addVoucher: action.payload,
        isFetchingGetAddVoucher: false,
      };
    case "ERROR_ADD_VOUCHER":
      return { ...state, isFetchingGetAddVoucher: false };

    case "REQUEST_ALL_VOUCHER":
      return { ...state, isFetchingGetAllVoucher: true };
    case "SUCCESS_ALL_VOUCHER":
      return {
        ...state,
        allVoucher: action.payload.data,
        isFetchingAllVoucher: false,
      };
    case "ERROR_ALL_VOUCHER":
      return { ...state, isFetchingAllVoucher: false };

    case "SUBMIT_CASH_PAYMENT":
      const { data, data1 } = action.payload;
      return {
        ...state,
        cashPayment: [
          ...state.cashPayment,
          {
            data,
          },
        ],
      };

    case "RESET_DATA":
      return { ...state, cashPayment: [] };

    case "SUBMIT_CASH_RECIPET":
      const { datacash } = action.payload;
      return {
        ...state,
        cashRecipet: [
          ...state.cashRecipet,
          {
            datacash,
          },
        ],
      };
    case "RESET_DATA1":
      return { ...state, cashRecipet: [] };

    case "SUBMIT_BANK_PAYMENT":
      const { databank } = action.payload;
      return {
        ...state,
        bankPayment: [
          ...state.bankPayment,
          {
            databank,
          },
        ],
      };
    case "RESET_DATA2":
      return { ...state, bankPayment: [] };

    case "SUBMIT_BANK_RECIPET":
      const { datarecipet } = action.payload;
      return {
        ...state,
        bankRecipet: [
          ...state.bankRecipet,
          {
            datarecipet,
          },
        ],
      };
    case "RESET_DATA3":
      return { ...state, bankRecipet: [] };

    case "SUBMIT_JOURNAL":
      const { datajournal } = action.payload;
      return {
        ...state,
        journal: [
          ...state.journal,
          {
            datajournal,
          },
        ],
      };

    case "RESET_DATA4":
      return { ...state, journal: [] };
    case "DELETE_CASH_PAYMENT":
      return {
        ...state,
        cashPayment: state.cashPayment.filter(
          (item) => item !== action.payload
        ),
      };

    case "DELETE_CASH_RECIPET":
      return {
        ...state,
        cashRecipet: state.cashRecipet.filter(
          (item) => item !== action.payload
        ),
      };

    case "DELETE_BANK_PAYMENT":
      return {
        ...state,
        bankPayment: state.bankPayment.filter(
          (item) => item !== action.payload
        ),
      };

    case "DELETE_BANK_RECIPET":
      return {
        ...state,
        bankRecipet: state.bankRecipet.filter(
          (item) => item !== action.payload
        ),
      };

    case "DELETE_JOURNAL":
      return {
        ...state,
        journal: state.journal.filter((item) => item !== action.payload),
      };

    case "SET_VALUE":
      return { ...state, val: action.payload };

    case "REQUEST_GetVoucher":
      return { ...state, isFetchingVoucherList: true };

    case "SUCCESS_GetVoucher":
      return {
        ...state,
        voucherList: action.payload.data,
        isFetchingVoucherList: false,
      };

    case "ERROR_GetVoucher":
      return { ...state, isFetchingVoucherList: false };

    case "REQUEST_SearchVoucher":
      return { ...state, isFetchingSearchVoucherList: true };

    case "SUCCESS_SearchVoucher":
      return {
        ...state,
        searchVoucherList: action.payload.data,
        isFetchingSearchVoucherList: false,
      };

    case "ERROR_SearchVoucher":
      return { ...state, isFetchingSearchVoucherList: false };

    // voucher verification

    case "REQUEST_ADMIN_VOUCHER":
      return { ...state, isFetchingadminVoucherList: true };
    case "SUCCESS_ADMIN_VOUCHER":
      return {
        ...state,
        voucherListUser: action.payload.data,
        isFetchingadminVoucherList: false,
      };
    case "ERROR_ADMIN_VOUCHER":
      return { ...state, isFetchingadminVoucherList: false };

    case "REQUEST_STATUS_UPDATE_VOUCHER":
      return { ...state };
    case "SUCCESS_STATUS_UPDATE_VOUCHER":
      return {
        ...state,
        voucherListUser: state.voucherListUser.map(
          (x) =>
            action.payload.data.find(
              ({ voucherId }) => voucherId === x.voucherId
            ) || x
        ),
      };
    case "ERROR_STATUS_UPDATE_VOUCHER":
      return { ...state };

    default:
      return state;
  }
};
export default VoucherReducer;
