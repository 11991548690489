import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty, get } from "lodash";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import IconMenu from "../../../drawer/IconMenu";
import { setHeading } from "../../../accounts/account.actions";


const DayOpenRecordTable = (props) => {

console.log(props.dayOpenData,"dataaaaaaaaaa")
useEffect(() => {
  // props.getDayOpening();
  props.setHeading("Day Open Record")
}, []);
  return (
    <>

      {props.isFetchingDayOpenData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingDayOpenData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
          <div className="sendDiv" style={{marginTop:"15px"}}>
            <Link to="/setting/dayOpen_record">
            <Button >
                 Back
                </Button>
                </Link>
            </div>
            <div className="table">
            
              <MaterialTable
                title={"Day Open Name"}
                
                columns={[
                  { title: "Date", field: "dayOpenDate" },

                  { title: "Day Open Name", field: "dayOpenName" },
                  { title: "Day Status", field: "status" },
                  { title: "cashInHand", field: "cashInHand" },

                  { title: "closingCash", field: "closingCash" },
                  { title: "Order Amount", field: "todaysOrderAmount" },
                  { title: "No# Order", field: "todaysOrderLength" },
                  { title: "Order Discount Amount", field: "todaysDiscountAmount" },
                  { title: "Order Cancelled Amount", field: "todaysCancelledAmount" },
                  { title: "No# Cancelled Order", field: "todaysCancelledOrderLength" },
                  
               
                  //   { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") }
                ]}
                data={props.dayOpenData.data}
                options={{
                  actionsColumnIndex: -1,
                  filtering: true,
                  exportButton: true,
                  paging: true,
                  pageSize: 200, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [50, 100, 150, 200],
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
              />
            </div>
      
          </div>
        </Container>
      )}
      </> 
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeading:(data)=>dispatch(setHeading(data))
  };
};
const mapStateToProps = (state, ownProps) => ({
  dayOpenData:state.ReportReducer.dayOpenData,
  isFetchingDayOpenData:state.ReportReducer.isFetchingDayOpenData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DayOpenRecordTable);
