import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import MaterialFlavors from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { baseURL, headers } from "../../../../../global/api";
import { deleteFlavors, getFlavorsData, updateFlavors } from "./Flavour.action";
import { setHeading } from "../../../accounts/account.actions";
import { useEffect } from "react";
const ViewFlavour = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    console.log(
      permissionsType,
      permissionsName,
      "typeeeeeeeeeeeeeeeeeeeeeeeee"
    );
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName)
      ? false
      : true;
  };
  useEffect(() => {
    props.setHeading("Flavour List");
  }, []);
  return (
    <>
      {props.isFetchingFlavors ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialFlavors
              title="Flavors Details"
              columns={[
                { title: "Flavors Name", field: "flavourName" },
                { title: "Flavors Description", field: "flavourDesc" },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/flavour?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.total_results,
                      });
                    });
                })
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50, // make initial page size
               
                emptyRowsWhenPaging: false, // To avoid of having empty rows
                pageSizeOptions: [30, 50, 100,150,200], // rows selection options
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                isEditHidden: (rowData) =>
                  Checkpermissions("permissionsSubCatName", "Flavour Edit"),
                isDeleteHidden: (rowData) =>
                  Checkpermissions("permissionsSubCatName", "Flavour Delete"),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateFlavors(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteFlavors(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFlavorsData: () => dispatch(getFlavorsData()),
    deleteFlavors: (tableId) => dispatch(deleteFlavors(tableId)),
    updateFlavors: (newData, oldData) =>
      dispatch(updateFlavors(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  flavorsDataList: state.FlavourReducer.flavorsDataList,
  isFetchingFlavors: state.FlavourReducer.isFetchingFlavors,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewFlavour);
