import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

import { getDayOpenReport } from "./Report.action";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import IconMenu from "../../../drawer/IconMenu";
import { setHeading } from "../../../accounts/account.actions";

const DayOpenRecord = (props) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();


  const exportPDF = () => {
    props.searchDayOpenRecord(
      {
        startDate: !isEmpty(startDate) ? startDate : "",
      },
    );
    navigate('/setting/dayOpen_record_table')
  };
  useEffect(() => {
    // props.getDayOpening();
    props.setHeading("Day Open Record")
}, []);

  return (
    <>
      {/* <Navbar style={{backgroundColor:'#81abd3'}}  >
      <Container>
        <Navbar.Brand style={{color:'white'}}><b>Day Open Record</b></Navbar.Brand>
        <IconMenu/>
      </Container>
    </Navbar> */}
      {/* {props.isFetchingLevel5LedgerData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingLevel5LedgerData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : ( */}
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              {/* <Col lg="6" md="12" sm="12">
              <Button className="sendButton" onClick={() => exportPDF()}>
                  Day Open Report
                </Button>
              </Col> */}
              </Row>
            <div className="sendDiv" style={{marginTop:"15px"}}>
            <Button  onClick={() => exportPDF()}>
                  Day Open Report
                </Button>
            </div>
          </div>
        </Container>
      {/* } */}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchDayOpenRecord:(data)=>dispatch(getDayOpenReport(data)),
    setHeading:(data)=>dispatch(setHeading(data))
  };
};
const mapStateToProps = (state, ownProps) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(DayOpenRecord);
