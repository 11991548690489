import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Container,
  Button,
  FormGroup,
  Alert,
  Image,
} from "react-bootstrap";
import { connect } from "react-redux";
import { filter, isEmpty, isNumber } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import MaterialSize from "material-table";
import "jspdf-autotable";
import Swal from "sweetalert";
import {
  EditRestData,
  EditRestaurant,
  addRestaurant,
  getRestaurantData,
} from "./Resturant.action";
import { setHeading } from "../../../accounts/account.actions";
import ImageUploader from "react-images-upload";
import { addProductImage } from "../AddProduct/Product.action";
import { FormControlLabel, Switch } from "@mui/material";
import Select from "react-select";
const EditResturant = (props) => {
  const [restaurant, setRestaurant] = useState("");
  const [restaurantDesc, setRestaurantDesc] = useState("");
  const [tax, setTax] = useState(0);
  const [ntnNumber, setNTNNumber] = useState(0);
  const [praOnCard, setPRAOnCard] = useState(0);
  const [praOnCash, setPRAOnCash] = useState(0);
  const [fbrOnCash, setFBROnCash] = useState(0);
  const [fbrOnCard, setFBROnCard] = useState(0);
  const [restaurantLocation, setRestaurantLocation] = useState("");
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantname, setRestaurantName] = useState("");
  const [formShow, setFormShow] = useState(false);

  // const [warehouseKey, setWarehouseKey] = useState();
  const [s1, setS1] = useState(isEmpty(props.restFilterData)
  ? ""
  : props.restFilterData[0].taxType);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const handleSwitch = () => {
    console.log(props.restFilterData[0].taxType,"testttttttttttt")
  if(props.restFilterData[0].taxType == "FBRTax") 
   { props.setEditData({
      key: "taxType",
      value: "PRATax",
    }) ;
    props.setEditData({
      key: "fbrTaxCash",
      value: 0,
    });
    props.setEditData({
      key: "fbrTaxCard",
      value: 0,
    })
    }
    else{ props.setEditData({
      key: "taxType",
      value: "FBRTax",
    });
    props.setEditData({
      key: "praTaxCash",
      value: 0,
    });
    props.setEditData({
      key: "praTaxCard",
      value: 0,
    })
  }
  };
  const handleSwitch1 = () => {
    props.restFilterData[0].taxType == "FBRTax" ?  props.setEditData({
      key: "taxType",
      value: "PRATax",
    }) : props.setEditData({
      key: "taxType",
      value: "FBRTax",
    })
  };
  console.log(s1, "testswitch");
  // const label1 = { inputProps: { 'aria-label': 'Yes' } };
  // console.log(label1,"testswitch")
  const onDrop = (pictureFiles, pictureDataURLs) => {
    console.log(pictureFiles, pictureDataURLs, "yyy");
    props.ProductImage({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };
  function uploadRestaurant() {
    props.addRestaurant(
      {
        restaurantName: restaurant,
        restaurantLocation: restaurantLocation,
        restaurantDesc: restaurantDesc,
        tax: tax,
        image: props.ProductImageURL.file,
        ntn: ntnNumber,
        praTaxCash: praOnCash,
        praTaxCard: praOnCard,
        fbrTaxCard: fbrOnCard,
        fbrTaxCash: fbrOnCash,
        taxType: s1,
      },
      handleVisible,
      handleDangerVisible
    );
    setRestaurantDesc("");
    setS1("PRATax");
  }
  const handleChangeRestaurant = (selectedOption) => {
    console.log(selectedOption, "sop");
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
    props.editResturant(selectedOption.value);
    setFormShow(true);
  };

  const getRestaurantList =
    !isEmpty(props.restaurantDataList) &&
    props.restaurantDataList.map((x) => {
      let data = { value: x._id, label: x.restaurantName };
      return data;
    });
  console.log(props.restFilterData, "dataaaaaaaaaaa");
  useEffect(() => {
    props.setHeading("Add Resturant");
    props.getRestaurantData();
    // console.log(props.couponsDataList, "list");
  }, [props.restFilterData[0]]);
  return (
    <>
      {props.isAddingRes || props.isFetchingRestaurant ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col xl="12" lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select Restaurant:</b>
                    </Form.Label>
                    <Select
                      isDisabled={isEmpty(props.restaurantDataList)}
                      placeholder="Select Restaurant..."
                      onChange={handleChangeRestaurant}
                      options={getRestaurantList}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div hidden={formShow == false ? true : false}>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Restaurant Name :</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].restaurantName
                        }
                        placeholder="Restaurant Name...."
                        onChange={(e) =>
                          props.setEditData({
                            key: "restaurantName",
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Restaurant Location :</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].restaurantLocation
                        }
                        placeholder="Restaurant Location...."
                        onChange={(e) => {
                          props.setEditData({
                            key: "restaurantLocation",
                            value: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Restaurant Description :</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].restaurantDesc
                        }
                        placeholder="Restaurant Description...."
                        onChange={(e) =>
                          props.setEditData({
                            key: "restaurantDesc",
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Restaurant Tax Amount :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        max={100}
                        value={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].tax
                        }
                        placeholder="Restaurant Tax Amount...."
                        onChange={(e) =>
                          props.setEditData({
                            key: "tax",
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>NTN Number :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        value={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].ntn
                        }
                        placeholder="NTN Number...."
                        onChange={(e) =>
                          props.setEditData({
                            key: "ntn",
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="1" md="1" xs="1"></Col>
                  <Col lg="2" md="2" xs="2" style={{ marginTop: "31px" }}>
                    <Form.Group>
                      <Form.Label>
                        <b>PRA Tax</b>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col
                    lg="1"
                    md="1"
                    xs="1"
                    className="mt-4"
                    style={{ marginRight: "2%", marginLeft: "-5%" }}
                  >
                    <Switch defaultChecked={isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].taxType=="FBRTax"?true:false} onClick={handleSwitch} />
                  </Col>
                  <Col lg="2" md="2" xs="2" style={{ marginTop: "31px" }}>
                    <Form.Group>
                      <Form.Label>
                        <b>FBR Tax</b>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>PRA Tax on Cash :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        disabled={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].taxType == "FBRTax"
                            ? true
                            : false
                        }
                        value={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].praTaxCash
                        }
                        max={100}
                        placeholder="PRA Tax on Cash"
                        onChange={(e) =>
                          props.setEditData({
                            key: "praTaxCash",
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>PRA Tax on Card :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        value={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].praTaxCard
                        }
                        disabled={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].taxType == "FBRTax"
                            ? true
                            : false
                        }
                        max={100}
                        placeholder="PRA Tax on Card"
                        onChange={(e) =>
                          props.setEditData({
                            key: "praTaxCard",
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>FBR tax on cash:</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        value={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].fbrTaxCash
                        }
                        disabled={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].taxType == "PRATax"
                            ? true
                            : false
                        }
                        max={100}
                        placeholder="FPR tax on cash"
                        onChange={(e) =>
                          props.setEditData({
                            key: "fbrTaxCash",
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>FBR tax on card :</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        value={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].fbrTaxCard
                        }
                        disabled={
                          isEmpty(props.restFilterData)
                            ? ""
                            : props.restFilterData[0].taxType == "PRATax"
                            ? true
                            : false
                        }
                        max={100}
                        placeholder="FPR tax on card"
                        onChange={(e) =>
                          props.setEditData({
                            key: "fbrTaxCard",
                            value: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
                    {/* <FormControlLabel control={<Switch defaultChecked />} label="Label" /> */}
                  </Col>
                </Row>
                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b style={{ fontSize: "large" }}>
                          Upload Product Image
                        </b>
                      </Form.Label>
                      <ImageUploader
                        withIcon={false}
                        buttonText="Upload Image"
                        onChange={onDrop}
                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={999999999999999999999}
                        singleImage={true}
                        withPreview={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="sendDiv">
                  {!isEmpty(props.restFilterData)&&isEmpty(props.restFilterData[0].restaurantName) ||
                  !isEmpty(props.restFilterData)&&isEmpty(props.restFilterData[0].restaurantLocation) ||
                  isEmpty(props.ProductImageURL) ? (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",
                        width: "20%",
                        backgroundColor: "#1976d2",
                        border: "1px solid #1976d2",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button onClick={uploadRestaurant}>Save</Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRestaurant: (data, handleVisible, handleDangerVisible) =>
      dispatch(addRestaurant(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
    ProductImage: (data) => dispatch(addProductImage(data)),
    getRestaurantData: () => dispatch(getRestaurantData()),
    editResturant: (id) => dispatch(EditRestaurant(id)),
    setEditData: (data) => dispatch(EditRestData(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  restFilterData: state.ResturantReducer.restFilterData,
  restaurantList: state.ResturantReducer.restaurantList,
  isAddingRestaurant: state.ResturantReducer.isAddingRestaurant,
  ProductImageURL: state.ProductReducer.ProductImageURL,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditResturant);
