import { filter, isEmpty, toLower } from "lodash";
const initialState = {
    isAddingDayOpening: false,

    isGettingDayOpening: false,
    dayOpeningList: [],

    isAddingDayClosing: false,

};

const DayReducer = (state = initialState, action) => {
    switch (action.type) {
        // Add OPening
        case "REQUEST_ADD_DAY_OPENING":
            return { ...state, isAddingDayOpening: true };
        case "SUCCESS_ADD_DAY_OPENING":
            return {
                ...state,
                // dayOpeningList: action.payload,
                isAddingDayOpening: false,
            };
        case "ERROR_ADD_DAY_OPENING":
            return { ...state, isAddingDayOpening: false };

        // Get OPening
        case "REQUEST_GET_DAY_OPENING":
            return { ...state, isGettingDayOpening: true };
        case "SUCCESS_GET_DAY_OPENING":
            return {
                ...state,
                dayOpeningList: action.payload,
                isGettingDayOpening: false,
            };
        case "ERROR_GET_DAY_OPENING":
            return { ...state, isGettingDayOpening: false };

        // Add Closing
        case "REQUEST_ADD_DAY_CLOSING":
            return { ...state, isAddingDayClosing: true };
        case "SUCCESS_ADD_DAY_CLOSING":
            return {
                ...state,
                isAddingDayClosing: false,
            };
        case "ERROR_ADD_DAY_CLOSING":
            return { ...state, isAddingDayClosing: false };

        default:
            return state;
    }
};
export default DayReducer;