import API from "../../../../../global/api";
export const requestHallData = () => {
    return {
        type: "REQUEST_HALL_GET_DATA",
    };
};
export const successHallGetData = (data) => {
    return {
        type: "SUCCESS_HALL_GET_DATA",
        payload: data,
    };
};
export const errorHallGetData = () => {
    return {
        type: "ERROR_HALL_GET_DATA",
    };
};
export const requestAddHall = () => {
    return {
        type: "REQUEST_ADD_HALL",
    };
};
export const successAddHall = (data) => {
    return {
        type: "SUCCESS_ADD_HALL",
        payload: data,
    };
};
export const errorAddHall = () => {
    return {
        type: "ERROR_ADD_HALL",
    };
};
export const getHallData = () => {
    return (dispatch) => {
        dispatch(requestHallData());
        API.get(`/hall`).then((res) => {
            let getData = res.data;
            console.log(getData,'List')
            dispatch(successHallGetData(getData));
        }).catch((error) => {
            dispatch(errorHallGetData());
        })
    };
};
export const addHall = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddHall());
        API.post(`/hall`, data).then((res) => {
           // dispatch(getHallData());
            dispatch(successAddHall(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddHall());
            handleDangerVisible(error.response.data.message);
        })
    };
};
export const DeleteRequestHall = () => {
    return {
        type: "DELETE_REQUEST_HALL",
    };
};
export const DeleteSuccessHall = (user_id) => {
    return {
        type: "DELETE_SUCCESS_HALL",
        user_id: user_id,
    };
};
export const DeleteErrorHall = () => {
    return {
        type: "DELETE_ERROR_HALL",
    };
};
export const deleteHall = (vendorId) => {
    return dispatch => {
        dispatch(DeleteRequestHall());
        API.delete(`/hall/${vendorId}`)
            .then(res => {
               // dispatch(getHallData());
                dispatch(DeleteSuccessHall(vendorId))
            }).catch((error) =>
            {
                dispatch(DeleteErrorHall())
    });
    }
};
export const UpdateRequestHall = () => {
    return {
        type: "UPDATE_REQUEST_HALL",
    };
};
export const UpdateSuccessHall = (data) => {
    return {
        type: "UPDATE_SUCCESS_HALL",
        payload: data,
    };
};

export const UpdateErrorHall = () => {
    return {
        type: "UPDATE_ERROR_HALL",
    };
};

export const updateHall = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestHall());
        console.log(data,'hmhm')
        API.put(`/hall/${oldData._id}`,
            {
                hallName: data.hallName,
                hallLocation:data.hallLocation,
                hallDesc:data.hallDesc     
            },{
        })
            .then(res => {
               // dispatch(getHallData());
                dispatch(UpdateSuccessHall([data]))
            }).catch((error) =>
                dispatch(UpdateErrorHall()));
    }
};
